import React, { useEffect } from "react";

// !!! DATATABLE !
import Table from "./Table/StudentManageTable/KurumStudentManageDataTable";
import "./Table/styles.css";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// REDUX
import { useDispatch } from 'react-redux';
import { fetchTumKurumListe,fetchTumUserListe } from '../../store/actions/actions';


const KurumStudentManage = (props) => {

    const updateClickHandler = (updateAlan) => {
      // console.log("Güncelle", updateAlan);
    }
    const deleteClickHandler = deleteAlan => {
      // console.log("Sil", deleteAlan);
    }


    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchTumKurumListe());
      dispatch(fetchTumUserListe());
    }, [dispatch]);

    // console.log(props)

  return (
    <>
    <Table userData={props.userData} updateClickHandler={updateClickHandler} deleteClickHandler={deleteClickHandler} />
    <ToastContainer/>
    </>
  );
};

export default KurumStudentManage;