// store.js
import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import rootReducer from '../store/reducers/reducers'; // Root reducer'ın yerini düzgün ayarlayın.
// import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(
  rootReducer,

    applyMiddleware(thunk)
    // diğer configure seçenekleri

);

export default store;


// // store.js
// import { createStore, applyMiddleware } from 'redux';
// import { thunk } from 'redux-thunk'; // named import
// import rootReducer from '../store/reducers/reducers'; // Root reducer'ın yerini düzgün ayarlayın.
// import { composeWithDevTools } from 'redux-devtools-extension';

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(
//     applyMiddleware(thunk)
//     // diğer configure seçenekleri
//   )
// );

// export default store;