import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";

// AXIOS
import { userupdate } from "../../axios/axios";
// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminUserProfile = (props) => {

  const userData = props.userData;

  const [fullnameVal, setFullnameVal] = useState();
  const [emailVal, setEmailVal] = useState();
  const [phoneNumberVal, setPhoneNumberVal] = useState();
  const [TCNOVal, setTCNOVal] = useState();
  const [kurumAdVal, setKurumAdVal] = useState();
  const [kurumIDVal, setKurumIDVal] = useState();
  const [passwordVal, setPasswordVal] = useState();
  const [userTypeVal, setUserTypeVal] = useState();
  const [isActiveVal, setIsActiveVal] = useState();

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };


  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [updatedFormData, setUpdatedFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    TCNO: "",
    kurumAd: "",
    kurumID: "",
    password: "",
    userType: "",
    isActive: false,
  });


  useEffect(() => {
    if (userData !== undefined) {
      setFullnameVal(userData.fullname);
      setEmailVal(userData.email);
      setPhoneNumberVal(userData.phoneNumber);
      setTCNOVal(userData.TCNO);
      setKurumAdVal(userData.kurumAd);
      setKurumIDVal(userData.kurumID);
      setPasswordVal(userData.password);
      setUserTypeVal(userData.userType);
      setIsActiveVal(userData.isActive);
    }
  }, [userData]);


  return (
    <>
        <Modal.Body>
          <Container>
          <Row>
                    <h3 style={{textAlign:"center"}}>Kullanıcı Profil</h3>
                </Row>
            <Row className="justify-content-center">
              <Col className="kurumProfileCol" xs={12} md={9}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();

                    userupdate(userData._id, updatedFormData)
                      .then((res) => {
                        handleClose()

                        toast.success("Kullanıcı Güncelleme Başarılı !")
                        // console.log(res.data)
                      })
                      .catch((error) => {
                        if (error.response.data.message.includes("E11000")) {
                          const match = error.response.data.message.match(/\{([^}]+)\}/);
                          const duplicateKeyObject = match ? match[0] : "{}";
                          const errorMessage = `Bu değer zaten kullanımda: ${duplicateKeyObject}`;
                          // console.log(error)
                          toast.error(errorMessage);
                        } else {
                          toast.error(error.response.data.message);
                          // console.log(error)
                        }
                      });

                  }}
                >


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Ad Soyad</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFullnameVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, fullname: e.target.value })
                      }
                      type="name"
                      placeholder="Ad Soyad Giriniz"
                      value={fullnameVal || ""}
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-Posta</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setEmailVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, email: e.target.value })
                      }
                      type="email"
                      placeholder="E-Posta adresinizi giriniz"
                      value={emailVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>Telefon No</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setPhoneNumberVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, phoneNumber: e.target.value })
                      }
                      type="text"
                      placeholder="Telefon numaranızı giriniz"
                      value={phoneNumberVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicTC">
                    <Form.Label>TC Kimlik No</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setTCNOVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, TCNO: e.target.value })
                      }
                      type="text"
                      placeholder="Tc Kimlik no giriniz"
                      value={TCNOVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label>Kurum Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setKurumAdVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, kurumAd: e.target.value })
                      }
                      type="text"
                      readOnly
                      placeholder="Kurum Adı giriniz"
                      value={kurumAdVal || ""}
                      className="kurumProfileInput"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Parola</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setPasswordVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, password: e.target.value })
                      }
                      type="password"
                      placeholder="Parolanızı değiştirmek için yeni parola giriniz"
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formUserActive">
                    <Form.Label>Kullanıcı Durumu</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setIsActiveVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, isActive: e.target.value })
                    }>
                      <option value="" disabled>{isActiveVal === true ? "Aktif" : "Pasif"}</option>
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>
                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="d-grid">

                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Güncelle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>
        <ToastContainer></ToastContainer>
    </>
  );






};
export default AdminUserProfile;
