import React, { useEffect, useState } from "react";

// REDUX
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchVideoCozumListe } from '../../store/actions/actions';


// AXİOS

import { profilkurumbilgigetir } from "../../axios/axios";

// MODAL REACT-BOOTSTRAP
import { Modal } from "react-bootstrap";

// BİLDİRİM PAKETİ TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlayCircle, faPlus, faSearch, faX } from '@fortawesome/free-solid-svg-icons';

// CSS
import "../css/Ogrenci/OgrenciVideoSolution.css"
import "../css/Ogrenci/OgrenciModal.css"
import styled from "styled-components";

import soruBulunamadıKapak from "../img/sorubulunamadı.jpg";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const OgrenciVideoSolution = ({ userData }) => {

  const [videoCozumListe, setVideoCozumListe] = useState([]);
  const [profileCompanyInfo, setProfileCompanyInfo] = useState("");

  // Search area

  const [searchText, setSearchText] = useState("");

  // useEffect(() => {
  //   console.log(searchText)
  // }, [searchText])

  const filteredVideoCozumListe = videoCozumListe
  .filter((data) =>
    data.path.toLowerCase().includes(searchText.toLowerCase())
  )
  .sort((a, b) => {
    // Path değerlerini parçalarına ayır
    const aParts = a.path.match(/(\d+)|([^\d]+)/g);
    const bParts = b.path.match(/(\d+)|([^\d]+)/g);

    // Parçaları karşılaştır
    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aPart = aParts[i];
      const bPart = bParts[i];

      // Sayısal kısımları sayısal olarak karşılaştır
      if (!isNaN(aPart) && !isNaN(bPart)) {
        const numA = parseInt(aPart, 10);
        const numB = parseInt(bPart, 10);
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        // Metin kısımları alfabetik olarak karşılaştır
        if (aPart !== bPart) {
          return aPart.localeCompare(bPart);
        }
      }
    }

    // Eğer tüm parçalar eşitse, uzunluklarına göre karşılaştır
    return aParts.length - bParts.length;
  });


  const [filteredVideoCozumLoading, setFilteredVideoCozumLoading] = useState(true);

  useEffect(() => {
    // console.log(filteredVideoCozumListe)
    if (filteredVideoCozumListe.length > 0) {
      setFilteredVideoCozumLoading(false)
    }

  }, [filteredVideoCozumListe])

  const lastMatchedData = [];

  // useEffect(() => {
  //   console.log(lastMatchedData)
  // }, [lastMatchedData])


  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const [selectedDataContent, setSelectedDataContent] = useState(null);
  const [fullDataContent, setFullDataContent] = useState([]);

  useEffect(() => {
    profilkurumbilgigetir(userData.kurumID)
      .then((res) => {
        // console.log(res.data)
        setProfileCompanyInfo(res.data)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [])


  const [videoCozumLoading, setVideoCozumLoading] = useState(true);

  useEffect(() => {
    // console.log(videoCozumListe)
    if (videoCozumListe.length > 0) {
      setVideoCozumLoading(false)

    }

  }, [videoCozumListe])




  const selectedQuestion = selectedDataContent;

  // Önceki sorunun order_number'ını alıp bir önceki soruya geçme
  const handlePreviousQuestion = () => {
    const prevOrderNumber = parseInt(selectedQuestion.order_number) - 1;
    const prevIndex = fullDataContent[0].findIndex(question => parseInt(question.order_number) === prevOrderNumber);
    if (prevIndex >= 0) {
      setSelectedDataContent(fullDataContent[0][prevIndex]);
    }
  };

  // Sonraki sorunun order_number'ını alıp bir sonraki soruya geçme
  const handleNextQuestion = () => {
    const nextOrderNumber = parseInt(selectedQuestion.order_number) + 1;
    let nextIndex = fullDataContent[0].findIndex(question => parseInt(question.order_number) === nextOrderNumber);

    if (nextIndex < fullDataContent[0].length && nextIndex !== -1) {
      setSelectedDataContent(fullDataContent[0][nextIndex]);
    }
  };

  // Modal kapatma ve arkaplan karartmasını kapatma
  const handleClose = () => {
    setShow(false);
    var elements = document.getElementsByClassName('MuiBox-root')[0];
    var container = document.getElementsByClassName('container')[0];
    container.style.opacity = 1;
    elements.style.opacity = 1;
  };

  // Modal açma ve arkaplan karartmasını açma
  const handleShow = (dataContent, fulldata) => {
    // console.log(fulldata)
    // console.log(dataContent)
    setSelectedDataContent(dataContent);
    setFullDataContent(fulldata)
    setShow(true);

    var elements = document.getElementsByClassName('MuiBox-root')[0];
    var container = document.getElementsByClassName('container')[0];
    container.style.opacity = 0.2;
    elements.style.opacity = 0.2;

  };


  // Redux'tan state çekme
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideoCozumListe());
  }, [dispatch]);

  const videoCozumListeCagir = useSelector(state => state.videoCozumListe);

  useEffect(() => {
    setVideoCozumListe(videoCozumListeCagir);
    // console.log(videoCozumListe)
  }, [dispatch, videoCozumListeCagir, videoCozumListe]);


  const TreeNode = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    let fulldata = [];

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    if (data.contents) {
      fulldata.push(data.contents)
    }

    return (
      <>

        <div className="ogrenciDenemeKaynak" key={data.id}>
          <button onClick={handleToggle}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>
          <h5>{data.path}</h5>
        </div>

        {isExpanded && (
          <div className="ogrenciDenemeKaynakBranslar">
            {data.nodes.map((childData) => (
              <TreeNode key={childData.id} data={childData} />
            ))}
          </div>
        )}

        {isExpanded && data.contents && (
          <div className="ogrenciDenemeKaynakSoruAlan" >
            {data.contents.map((dataContent) => (
              <div className="ogrenciDenemeKaynakSoruDiv" key={dataContent.id}>
                <h3 className="ogrenciDenemeKaynakSoruH">{dataContent.name + " " + dataContent.order_number}</h3>
                <FontAwesomeIcon className="ogrenciDenemeKaynakSoruIcon"
                  onClick={() => handleShow(dataContent, fulldata)} icon={faPlayCircle} size="2x"></FontAwesomeIcon>
              </div>
            ))}
          </div>
        )}

      </>

    );
  };

  return (
    <>
      <div className="home_page">

        <div className="searchFilterAlan">
          <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
          <Input
            type="text"
            placeholder=" Video Çözüm Ara..."
            className="headerFilter"
            value={searchText}
            onChange={((e) => {
              setSearchText(e.target.value)
            })}
          />
          <ClearButton

            className="headerClear"
            onClick={(() => {
              setSearchText("")
            })}>
            <FontAwesomeIcon icon={faX} />
          </ClearButton>

        </div>

        <h1 className="mt-4 mb-4">Video Çözümler</h1>

        {videoCozumLoading && (
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <span>YÜKLENİYOR...</span>
          </div>
        )}

        <div className="ogrenciDenemeKaynakDiv">
          {/* {videoCozumListe.map((data) => {

            let kısıtlıData = profileCompanyInfo.paths && profileCompanyInfo.paths.includes(Number(data.id)) ? data : null;
            // console.log(kısıtlıData.includes)
            let studentClass = userData.studentClass.toLocaleLowerCase("tr-TR").replace(/\s/g, ''); // Boşlukları temizle
    let pathLowerCase = kısıtlıData ? kısıtlıData.path.toLocaleLowerCase("tr-TR").replace(/\s/g, '') : ''; // Boşlukları temizle

            let matchedData = null;

            if ((studentClass === "11.sınıf") && ["tyt", "ayt", "12.sınıf", "11.sınıf"].some(item => pathLowerCase.includes(item))) {
                matchedData = data;
                lastMatchedData.push(matchedData)
            }
            else if ((studentClass === "12.sınıf") && ["tyt", "ayt", "12.sınıf"].some(item => pathLowerCase.includes(item))) {
              matchedData = data;
              lastMatchedData.push(matchedData)
            }
            else if (pathLowerCase.includes(studentClass)) {
              matchedData = data;
              lastMatchedData.push(matchedData)
            }

            return (
              <React.Fragment key={matchedData ? matchedData.path : data.path}>
                  {!videoCozumLoading && matchedData && (

                  <div key={matchedData.path}>
                    <TreeNode key={matchedData.path} data={matchedData} handleShow={handleShow} show={show} selectedDataContent={selectedDataContent} />
                  </div>
                )}
              </React.Fragment>
            );
          })} */}
          <>
            {filteredVideoCozumLoading &&
              <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <span>YÜKLENİYOR...</span>
              </div>}
            {!filteredVideoCozumLoading && (
              <div>
                {filteredVideoCozumListe.map((data) => {

                  let kısıtlıData = profileCompanyInfo.paths && profileCompanyInfo.paths.includes(Number(data.id)) ? data : null;
                  // console.log(kısıtlıData.includes)
                  let studentClass = userData.studentClass.toLocaleLowerCase("tr-TR").replace(/\s/g, ''); // Boşlukları temizle
          let pathLowerCase = kısıtlıData ? kısıtlıData.path.toLocaleLowerCase("tr-TR").replace(/\s/g, '') : ''; // Boşlukları temizle

                  let matchedData = null;

                  if ((studentClass === "11.sınıf") && ["tyt", "ayt", "12.sınıf", "11.sınıf"].some(item => pathLowerCase.includes(item))) {
                      matchedData = data;
                      lastMatchedData.push(matchedData)
                  }
                  else if ((studentClass === "12.sınıf") && ["tyt", "ayt", "12.sınıf"].some(item => pathLowerCase.includes(item))) {
                    matchedData = data;
                    lastMatchedData.push(matchedData)
                  }
                  else if (pathLowerCase.includes(studentClass)) {
                    matchedData = data;
                    lastMatchedData.push(matchedData)
                  }

                  return (
                    <React.Fragment key={matchedData ? matchedData.path : data.path}>
                        {!videoCozumLoading && matchedData && (

                        <div key={matchedData.path}>
                          <TreeNode key={matchedData.path} data={matchedData} handleShow={handleShow} show={show} selectedDataContent={selectedDataContent} />
                        </div>
                      )}
                    </React.Fragment>
                  );

                })}
              </div>
            )}
          </>

          {show && selectedDataContent && (

            <Modal className="modal ogrenciVideoSolutionModalDialogModal "
              dialogClassName="modal-dialog ogrenciVideoSolutionModalDialog" show={show} onHide={handleClose}>

              <Modal.Header closeButton className="videoSolutionModalHeader">
                <Modal.Title>{selectedQuestion.name + " " + selectedQuestion.order_number}</Modal.Title>
              </Modal.Header>

              <Modal.Body className="videoSolutionModalBody">

              {selectedQuestion.solved ?
                  <iframe
                    title={selectedQuestion.name}
                    width={"100%"}
                    height={"100%"}
                    allowFullScreen={true}
                    className="modalVideoSolutionIframe"
                    src={selectedQuestion.solved}>
                  </iframe>
                  :
                  <img className="videoSolutionQuestionNotFoundImg" src={soruBulunamadıKapak} alt="Soru Bulunamadı"/>
                }

              </Modal.Body>

              <div className="ogrenciVideoSolutionModalQuestionArea videoSolutionModalFooter" >
                <div className="ogrenciVideoSolutionModalPrevquestion">
                  <p> Önceki Soru </p>
                  <FontAwesomeIcon className="ogrenciVideoSolutionModalIcon" onClick={handlePreviousQuestion} size="2x" icon={faChevronLeft} ></FontAwesomeIcon>
                </div>
                <div className="ogrenciVideoSolutionModalNextquestion">
                  <p> Sonraki Soru </p>
                  <FontAwesomeIcon className="ogrenciVideoSolutionModalIcon" onClick={handleNextQuestion} size="2x" icon={faChevronRight}></FontAwesomeIcon>
                </div>

              </div>
            </Modal>


          )}
        </div>
        {
          !videoCozumLoading && lastMatchedData.length <= 0
            ?
            (searchText
              ? <h4>Filtreye uygun bir video çözüm bulunamadı!</h4>
              : <h4>Kurumunuz henüz bir video çözüm yüklemesi yapmamıştır!</h4>)

            :
            null
        }

      </div>
      <ToastContainer />
    </>

  );
};


export default OgrenciVideoSolution;