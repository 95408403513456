import React, { useEffect, useState } from "react";
//REDUX
import { useSelector } from 'react-redux';
// BOOTSTRAP
import { Container, Form, Button, Row, Col, Modal,FormCheck } from "react-bootstrap";
// AXIOS
import { kurumekle } from "../../../../axios/axios";
// SELECT OPTİON'DA KULLANILAN İLLER
import sehirler from "../../../../service/iller.json";
// MODAL CSS 
import "../../../css/Admin/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const AdminCompanyAdd = (props) => {

  const videoCozumListe = useSelector(state => state.videoCozumListe);
  const tumKurumListe = useSelector(state => state.tumKurumListe);

  const [selectedVideoItems, setSelectedVideoItems] = useState([]);

  console.log("Video Çözüm Liste:", videoCozumListe);
  console.log("Tüm Liste:", tumKurumListe);

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [formData, setFormData] = useState({
    kurumAdı: "",
    sehir: "",
    telefonNo: "",
    email: "",
    kurumTur: "",
    kurumAdres: "",
    isActive: false,
    paths: [],
  });

  const formDataSıfırla = () => {
    setFormData({
      kurumAdı: "",
      sehir: "",
      telefonNo: "",
      email: "",
      kurumTur: "",
      kurumAdres: "",
      isActive: false,
      paths: [],
    });
  }

  const [secilenSehir, setSecilenSehir] = useState();

  useEffect(() => {
    console.log(secilenSehir)
  }, [secilenSehir])


  useEffect(()=>{
console.log(selectedVideoItems)
console.log(formData)
  },[selectedVideoItems,formData])

  return (
    <>
      <Button className="adminCompanyAddButton companyListAddButton" variant="primary" onClick={handleShow}>
        <FontAwesomeIcon className="me-3" icon={faPlus} size="1x" />
        <p style={{display:"inline", fontFamily:"sans-serif", fontSize:"18px"}}>Yeni Oluştur</p>
      </Button>

      <Modal dialogClassName="modal-dialog adminCompanyAddModalDialog" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kurum Ekleme</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
                <Form
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(formData)
                    kurumekle(formData)
                      .then((res) => {
                        handleClose()
                        toast.success("Kayıt Ekleme Başarılı !")
                        formDataSıfırla()
                        console.log(res)
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message);
                        console.log(err);
                      })

                  }}
                >


                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label>Kurum Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kurumAdı: e.target.value.toLocaleUpperCase('tr-TR') })
                      }
                      type="name"
                      placeholder="Kurum Adı Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCity">
                    <Form.Label>Şehir Seç</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) => {

                      setFormData({ ...formData, sehir: e.target.value });
                      setSecilenSehir(e.target.value);

                      // console.log(e.target.options[e.target.selectedIndex].getAttribute('data-plaka'));
                    }}>
                      <option value="" disabled>Şehir Seç</option>

                      {sehirler.map((iller) => {
                        return (
                          <option key={iller.plaka} data-plaka={iller.plaka}>{iller.il_adi}</option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>



                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>Telefon No</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setFormData({ ...formData, telefonNo: e.target.value });
                      }
                     }
                      type="number"
                      placeholder="Telefon numaranızı giriniz (0545 111 1111)"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-Posta</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value.toLowerCase() })
                      }
                      type="email"
                      placeholder="E-Posta adresinizi giriniz"
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicCompanyType">
                    <Form.Label>Kurum Türü</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, kurumTur: e.target.value }) + console.log(e.target.value)
                    }>
                      <option value="" disabled>Kurum Türü Seçiniz</option>
                      <option>Kolej</option>
                      <option>Kurs</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kurum Durumu</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) => {
                      setFormData({ ...formData, isActive: e.target.value });
                      console.log(e.target.value)
                    }
                    }>
                      <option value="" disabled>Kurum Durumu Seçiniz</option>
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>


                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCompanyAdress">
                    <Form.Label>Kurum Adresi</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kurumAdres: e.target.value })
                      }
                      as="textarea"
                      rows={3}
                      placeholder="Adres giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex flex-wrap" controlId="formBasicCompanyActive">
                  <Form.Label style={{display:"block", width:"100%"}}>Kurum Video Çözümleri</Form.Label>
                                {videoCozumListe.map((videocozum) => {
                                    return (
                                        <Col xs={6} sm={4} md={4} xxl={3}
                                         key={videocozum.id}  className="adminProfileVideoSolution">
                                            <Form.Label className="me-2" style={{width:"80%"}}>{videocozum.path}</Form.Label>
                                            <FormCheck value={videocozum.id} style={{width:"20%"}} type="switch" id="custom-switch"
                                             onChange={() => {
                                              
                                               setFormData((prevData) => {
                                                const isIdSelected = prevData.paths.includes(videocozum.id);
                                            
                                                return {
                                                  ...prevData,
                                                  paths: isIdSelected
                                                    ? prevData.paths.filter((item) => item !== videocozum.id) // Eğer zaten varsa çıkar
                                                    : [...prevData.paths, videocozum.id],                     // Yoksa ekle
                                                };
                                              });

                                              }
                                               
                                               } />
                                        </Col>

                                    )
                                })}

                            </Form.Group>


                  <Form.Group className="d-grid">


                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Kayıt Ekle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );






};
export default AdminCompanyAdd;
