import React, { useState } from 'react';

const AdminVideoSolutionDataUpdateButton = ({progress,kaynakDataAl}) => {
  const [loading, setLoading] = useState(false);
  const progressPercentage = Math.min(Math.round(progress), 100);

  const handleButtonClick = async () => {
    setLoading(true);
    await kaynakDataAl(); // KaynakDataAl fonksiyonunu çalıştırın
    setLoading(false);
  };

  return (
    <>
    {/* Güncelleme butonu */}
    <button className="adminVideoSolutionDataUpdateButton ms-5" onClick={handleButtonClick}>
        Kaynak Güncelle
      </button>

      {/* İlerleme durumunu göstermek için bir bileşen */}
      {loading && (
        <div className='ms-5'>
          <div>Güncelleniyor... {progressPercentage}%</div>
          <div style={{ width: '100%', height: '20px', background: '#eee' }}>
            <div
              style={{
                width: `${progressPercentage}%`,
                height: '100%',
                background: '#4caf50',
                transition: 'width 1s ease',
              }}
            ></div>
          </div>
        </div>
      )}

    </>

  );
};

export default AdminVideoSolutionDataUpdateButton;