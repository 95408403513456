import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { userupdate } from "../../axios/axios";

// SELECT OPTİON'DA KULLANILAN İLLER
import sehirler from "../../service/iller.json";
import siniflar from "../../service/siniflar.json";
// MODAL CSS
import "../css/Admin/AdminProfile.css"
// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// REDUX
import { useSelector } from "react-redux";


const AdminUserProfile = (props) => {

  const [kurumlar, setKurumlar] = useState([]);
  const [sehirdekiKurumlar, setSehirdekiKurumlar] = useState([]);
  const olanSehirler = kurumlar.map((kurum) => kurum.sehir)

  const tumKurumListe = useSelector(state => state.tumKurumListe);
  const userData = props.userData;

  useEffect(() => {
    setKurumlar(tumKurumListe);
  }, [tumKurumListe])

  // console.log(props)

  const getKurumlarBySehir = (sehir) => {
    const kurumlarBySehir = kurumlar.filter((kurum) => kurum.sehir === sehir);
    setSehirdekiKurumlar(kurumlarBySehir);
    // console.log(sehirdekiKurumlar)
  };

  // console.log(props)
  const [fullnameVal, setFullnameVal] = useState();
  const [emailVal, setEmailVal] = useState();
  const [phoneNumberVal, setPhoneNumberVal] = useState();
  const [TCNOVal, setTCNOVal] = useState();
  const [kurumAdVal, setKurumAdVal] = useState();
  const [kurumIDVal, setKurumIDVal] = useState();
  const [passwordVal, setPasswordVal] = useState();
  const [studentClassVal, setStudentClassVal] = useState();
  const [userTypeVal, setUserTypeVal] = useState();
  const [isActiveVal, setIsActiveVal] = useState();

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };


  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [updatedFormData, setUpdatedFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    TCNO: "",
    kurumAd: "",
    kurumID: "",
    password: "",
    studentClass: "",
    userType: "",
    isActive: false,
  });


  useEffect(() => {
    if (userData !== undefined) {
      setFullnameVal(userData.fullname);
      setEmailVal(userData.email);
      setPhoneNumberVal(userData.phoneNumber);
      setTCNOVal(userData.TCNO);
      setKurumAdVal(userData.kurumAd);
      setKurumIDVal(userData.kurumID);
      setPasswordVal(userData.password);
      setStudentClassVal(userData.studentClass);
      setUserTypeVal(userData.userType);
      setIsActiveVal(userData.isActive);
    }
  }, [userData]);


  return (
    <>
      <Modal.Body>
        <Container>
        <Row>
                    <h3 style={{textAlign:"center"}}>Kullanıcı Profil</h3>
                </Row>
          <Row  className="justify-content-center">
            <Col className="adminProfileCol" xs={12} md={9}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();

                  userupdate(userData._id, updatedFormData)
                    .then((res) => {
                      handleClose()

                      toast.success("Kullanıcı Güncelleme Başarılı !")
                      // console.log(res.data)
                    })
                    .catch((error) => {
                      if (error.response.data.message.includes("E11000")) {
                        const match = error.response.data.message.match(/\{([^}]+)\}/);
                        const duplicateKeyObject = match ? match[0] : "{}";
                        const errorMessage = `Bu değer zaten kullanımda: ${duplicateKeyObject}`;
                        // console.log(error)
                        toast.error(errorMessage);
                      } else {
                        toast.error(error.response.data.message);
                        // console.log(error)
                      }
                    });

                }}
              >


                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Ad Soyad</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setFullnameVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, fullname: e.target.value })
                    }
                    type="name"
                    placeholder="Ad Soyad Giriniz"
                    value={fullnameVal}
                className="adminProfileInput"
                  />
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-Posta</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setEmailVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, email: e.target.value })
                    }
                    type="email"
                    placeholder="E-Posta adresinizi giriniz"
                    value={emailVal}
                    className="adminProfileInput"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                  <Form.Label>Telefon No</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setPhoneNumberVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, phoneNumber: e.target.value })
                    }
                    type="text"
                    placeholder="Telefon numaranızı giriniz"
                    value={phoneNumberVal}
                    className="adminProfileInput"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicTC">
                  <Form.Label>TC Kimlik No</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setTCNOVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, TCNO: e.target.value })
                    }
                    type="text"
                    placeholder="Tc Kimlik no giriniz"
                    value={TCNOVal}
                    className="adminProfileInput"
                  />
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicSehir">
                  <Form.Label>Şehir</Form.Label>
                  <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) => {

                    setUpdatedFormData({ ...updatedFormData, sehir: e.target.value })

                    const selectedSehir = e.target.value;
                    getKurumlarBySehir(selectedSehir);

                  }

                  }>
                    <option value="" disabled>Şehir Seç</option>
                    {sehirler.map((iller) => {
                      if (!olanSehirler.includes(iller.il_adi)) {
                        return null;
                      }
                      return (
                        <option key={iller.plaka}>{iller.il_adi}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicCompanyName">
                  <Form.Label>Kurum Adı</Form.Label>
                  <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    const kurumAd = e.target.value;
                    const kurumID = selectedOption.getAttribute("data-kurumid");
                    setUpdatedFormData({ ...updatedFormData, kurumAd, kurumID });
                    // console.log(kurumAd, kurumID)
                  }

                  }>
                    <option value="" disabled>{kurumAdVal}</option>
                    {
                      sehirdekiKurumlar ? sehirdekiKurumlar.map((kurum) => {
                        return (
                          <option disabled={kurum.isActive ? false : true} data-kurumid={kurum._id} key={kurum._id}>
                            {kurum.isActive ? kurum.kurumAdı : `${kurum.kurumAdı} (Kurum Aktif Değil)`}
                          </option>
                        )
                      })
                        : null
                    }
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicSınıf">
                  <Form.Label>Sınıf</Form.Label>
                  <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) => {
                    setUpdatedFormData({ ...updatedFormData, studentClass: e.target.value })
                  }
                  }>
                    <option value="" disabled>{studentClassVal}</option>
                    {siniflar.map((sinif) => {
                      return (
                        <option>{sinif.sinif}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Parola</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setPasswordVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, password: e.target.value })
                    }
                    type="password"
                    placeholder="Parolanızı giriniz"
                    value={passwordVal}
                    className="adminProfileInput"
                  />
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicUserType">
                  <Form.Label>Kullanıcı Türü</Form.Label>
                  <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) =>
                    setUserTypeVal(e.target.value) +
                    setUpdatedFormData({ ...updatedFormData, userType: e.target.value })
                  }>
                    <option value="" disabled>{userTypeVal}</option>
                    <option value="OGRENCI">OGRENCI</option>
                    <option value="KURUM">KURUM</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formUserActive">
                  <Form.Label>Kullanıcı Durumu</Form.Label>
                  <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) =>
                    setIsActiveVal(e.target.value) +
                    setUpdatedFormData({ ...updatedFormData, isActive: e.target.value })
                  }>
                    <option value="" disabled>{isActiveVal === true ? "Aktif" : "Pasif"}</option>
                    <option value="true">Aktif</option>
                    <option value="false">Pasif</option>
                  </Form.Select>
                </Form.Group>




                <Form.Group className="d-grid">


                  <Button variant="primary" type="submit" className="mt-4 w-100">
                    Güncelle
                  </Button>

                  <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                    Vazgeç
                  </Button>

                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>


      </Modal.Body>
      <ToastContainer></ToastContainer>
    </>
  );






};
export default AdminUserProfile;
