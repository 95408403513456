// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ogrenciDenemeKaynakDiv {
    display: flex;
    flex-direction: column;
}

.ogrenciDenemeKaynak {
    background-color: rgb(55 133 209);
    margin-bottom: 5px;
    display: flex;
    padding: 5px;
}
.ogrenciDenemeKaynak h5{
margin: 0;
margin-left: 8px
}

.ogrenciDenemeKaynakBranslar {
    margin-left: 28px;
}

.ogrenciDenemeKaynakSoruAlan {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
}

.ogrenciDenemeKaynakSoruDiv {
    display: flex;
    align-items: center;
    width: 160px;
    margin: 10px;
    padding: 5px;
    border: 1px solid;
}

.ogrenciDenemeKaynakSoruH {
    width: 110px;
    margin: 0;
    text-align: center;
}
.ogrenciDenemeKaynakSoruIcon{
    cursor: pointer;
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/Ogrenci/OgrenciVideoSolution.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;AACA;AACA,SAAS;AACT;AACA;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".ogrenciDenemeKaynakDiv {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.ogrenciDenemeKaynak {\r\n    background-color: rgb(55 133 209);\r\n    margin-bottom: 5px;\r\n    display: flex;\r\n    padding: 5px;\r\n}\r\n.ogrenciDenemeKaynak h5{\r\nmargin: 0;\r\nmargin-left: 8px\r\n}\r\n\r\n.ogrenciDenemeKaynakBranslar {\r\n    margin-left: 28px;\r\n}\r\n\r\n.ogrenciDenemeKaynakSoruAlan {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-left: 20px;\r\n}\r\n\r\n.ogrenciDenemeKaynakSoruDiv {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 160px;\r\n    margin: 10px;\r\n    padding: 5px;\r\n    border: 1px solid;\r\n}\r\n\r\n.ogrenciDenemeKaynakSoruH {\r\n    width: 110px;\r\n    margin: 0;\r\n    text-align: center;\r\n}\r\n.ogrenciDenemeKaynakSoruIcon{\r\n    cursor: pointer;\r\n    width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
