// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.openBoxDiv{
    position: relative;
}
.openUserBox{
    padding: 20px;
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: #0d6efd;
    border-radius: 5px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.openUserBox h6{
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/layout/css/Topbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB","sourcesContent":[".openBoxDiv{\n    position: relative;\n}\n.openUserBox{\n    padding: 20px;\n    position: absolute;\n    top: 50px;\n    right: 0px;\n    background-color: #0d6efd;\n    border-radius: 5px;\n    z-index: 3;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.openUserBox h6{\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
