import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Button, Row, Col, FormCheck } from "react-bootstrap";

// AXIOS
import { kurumupdate, profilkurumbilgigetir } from "../../axios/axios";

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideoCozumListe } from '../../store/actions/actions';

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CSS
import "../css/Kurum/KurumCompanyProfile.css"


const KurumCompanyProfile = (props) => {
    // console.log(props)

    const [kurumAdıVal, setKurumAdıVal] = useState('');
    const [sehirVal, setSehirVal] = useState('');
    const [telefonNoVal, setTelefonNoVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    const [kurumTurVal, setKurumTurVal] = useState('');
    const [kurumAdresVal, setKurumAdresVal] = useState('');
    const [isActiveVal, setIsActiveVal] = useState('');
    const [pathsVal, setPathsVal] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const [profileCompanyInfo, setProfileCompanyInfo] = useState([]);

    const videoCozumListe = useSelector(state => state.videoCozumListe);

    const filteredVideoCozumListe = videoCozumListe
    .sort((a, b) => {
      // Path değerlerini parçalarına ayır
      const aParts = a.path.match(/(\d+)|([^\d]+)/g);
      const bParts = b.path.match(/(\d+)|([^\d]+)/g);

      // Parçaları karşılaştır
      for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
        const aPart = aParts[i];
        const bPart = bParts[i];

        // Sayısal kısımları sayısal olarak karşılaştır
        if (!isNaN(aPart) && !isNaN(bPart)) {
          const numA = parseInt(aPart, 10);
          const numB = parseInt(bPart, 10);
          if (numA !== numB) {
            return numA - numB;
          }
        } else {
          // Metin kısımları alfabetik olarak küçük harflerle karşılaştır
          const lowerA = aPart.toLowerCase();
          const lowerB = bPart.toLowerCase();
          if (lowerA !== lowerB) {
            return lowerA.localeCompare(lowerB);
          }
        }
      }
      // Eğer tüm parçalar eşitse, uzunluklarına göre karşılaştır
      return aParts.length - bParts.length;
    });

    const phoneNumberRef = useRef();

    // Redux'tan state çekme

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVideoCozumListe());
    }, [dispatch]);

    //MODAL DEĞİŞKENLERİ
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    useEffect(() => {
        profilkurumbilgigetir(props.userData.kurumID)
            .then((res) => {
                // console.log(res.data)
                setProfileCompanyInfo(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // console.log(profileCompanyInfo)
    // console.log(props.userData.kurumID)

    // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
    const [updatedFormData, setUpdatedFormData] = useState({
        kurumAdı: "",
        sehir: "",
        telefonNo: "",
        email: "",
        kurumTur: "",
        kurumAdres: "",
        isActive: false,
        paths: null,
    });

    useEffect(() => {
        // console.log(updatedFormData)
    }, [updatedFormData])

    useEffect(() => {
        if (profileCompanyInfo !== undefined) {
            setKurumAdıVal(profileCompanyInfo.kurumAdı)
            setSehirVal(profileCompanyInfo.sehir)
            setTelefonNoVal(profileCompanyInfo.telefonNo)
            setEmailVal(profileCompanyInfo.email)
            setKurumTurVal(profileCompanyInfo.kurumTur)
            setKurumAdresVal(profileCompanyInfo.kurumAdres)
            setIsActiveVal(profileCompanyInfo.isActive);
            setPathsVal(profileCompanyInfo.paths);
            setCheckedItems(profileCompanyInfo.paths); // Burada güncelleme yapılıyor
        }
    }, [profileCompanyInfo])


    return (
        <>
            <Container>
                <Row>
                    <h3 style={{ textAlign: "center" }}>Kurum Profil</h3>
                </Row>
                <Row className="justify-content-center">
                    <Col className="kurumProfileCol" xs={12} md={9}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();

                                if (phoneNumberRef.current.value.length !== 11) {
                                    toast.error("Telefon Numarası 11 haneli olmalıdır.");
                                }
                                else {
                                    kurumupdate(profileCompanyInfo._id, updatedFormData)
                                        .then((res) => {
                                            handleClose()

                                            toast.success("Kurum Güncelleme Başarılı !")
                                            // console.log(res.data)
                                        })
                                        .catch((err) => toast.error("Kurum Güncelleme Başarısız", err.response.data.message));
                                }
                            }}
                        >

                            <Form.Group className="mb-3" controlId="formBasicCompanyName">
                                <Form.Label>Kurum Adı</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Kurum Adı Giriniz"
                                    value={kurumAdıVal || ""}  // undefined durumu için boş string
                                    readOnly
                                    className="kurumProfileInput"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCity">
                                <Form.Label>Şehir</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Şehir Adı Giriniz"
                                    value={sehirVal || ""}  // undefined durumu için boş string
                                    readOnly
                                    className="kurumProfileInput"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                                <Form.Label>Telefon No</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setTelefonNoVal(value);
                                        setUpdatedFormData({ ...updatedFormData, telefonNo: value });
                                    }}
                                    type="number"
                                    placeholder="Telefon numaranızı giriniz"
                                    value={telefonNoVal || ""}  // undefined durumu için boş string
                                    ref={phoneNumberRef}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-Posta</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        const value = e.target.value.toLowerCase();
                                        setEmailVal(value);
                                        setUpdatedFormData({ ...updatedFormData, email: value });
                                    }}
                                    type="email"
                                    placeholder="E-Posta adresinizi giriniz"
                                    value={emailVal || ""}  // undefined durumu için boş string
                                />
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicCompanyType">
                                <Form.Label>Kurum Türü</Form.Label>
                                <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                                    setKurumTurVal(e.target.value) +
                                    setUpdatedFormData({ ...updatedFormData, kurumTur: e.target.value })
                                }>
                                    <option value="" disabled>{kurumTurVal}</option>
                                    <option>Kolej</option>
                                    <option>Kurs</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCompanyAdress">
                                <Form.Label>Kurum Adresi</Form.Label>
                                <Form.Control
                                    onChange={(e) =>
                                        setKurumAdresVal(e.target.value) +
                                        setUpdatedFormData({ ...updatedFormData, kurumAdres: e.target.value })
                                    }
                                    as="textarea"
                                    rows={3}
                                    placeholder="Adres giriniz"
                                    value={kurumAdresVal}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCompanyActive">
                                <Form.Label>Kurum Durumu</Form.Label>
                                <Form.Select defaultValue="" className="ps-5 " onChange={(e) =>
                                    setIsActiveVal(e.target.value) +
                                    setUpdatedFormData({ ...updatedFormData, isActive: e.target.value })
                                }>
                                    <option value="" disabled>{isActiveVal === true ? "Aktif" : "Pasif"}</option>
                                    <option value="true">Aktif</option>
                                    <option value="false">Pasif</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 kurumProfileVideoSolutionGroup" controlId="formBasicCompanyActive">
                                <Form.Label className="kurumProfileVideoSolutionLabel">Kurum Video Çözümleri</Form.Label>
                                <div className="kurumProfileVideoSolutionDiv">
                                    {filteredVideoCozumListe.map((videocozum) => {
                                        return (
                                            <Col xs={6} sm={4} md={4} xxl={3}
                                                className="kurumProfileVideoSolution"
                                                key={videocozum.id}>

                                                <Form.Label className="kurumProfileVideoSolutionLabel">{videocozum.path}</Form.Label>
                                                <FormCheck
                                                    value={videocozum.id}
                                                    className="kurumProfileVideoSolutionSwitch"
                                                    type="switch"
                                                    id={`custom-switch-${videocozum.id}`}
                                                    checked={checkedItems ? checkedItems.includes(Number(videocozum.id)) : false} // checkedItems null/undefined ise false döner
                                                    onChange={(e) => {
                                                        let newCheckedItems;
                                                        if (e.target.checked) {
                                                            newCheckedItems = [...checkedItems, Number(videocozum.id)];
                                                        } else {
                                                            newCheckedItems = checkedItems.filter(item => item !== Number(videocozum.id));
                                                        }
                                                        setCheckedItems(newCheckedItems);
                                                        setUpdatedFormData({ ...updatedFormData, paths: newCheckedItems });

                                                    }}
                                                />
                                            </Col>
                                        )
                                    })}
                                </div>

                            </Form.Group>



                            <Form.Group className="d-grid">


                                <Button variant="primary" type="submit" className="mt-4 w-100">
                                    Güncelle
                                </Button>

                                <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                                    Vazgeç
                                </Button>

                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );

};
export default KurumCompanyProfile;
