import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// BİLDİRİM İÇİN PAKET
import { ToastContainer, toast } from "react-toastify";
// LOGOLAR VE CSS
import "./css/LoginAndSignup/SignupScreen.css"
import acilogo from "./img/aciegitimkurumlarilogo.png";

// AXİOS
import { signup, kurumlisteler } from "../axios/axios.js";

// İLLERİN OLDUĞU JSON
import sehirler from "../service/iller.json";
import siniflar from "../service/siniflar.json";

const SignupPage = () => {
  const [kurumlar, setKurumlar] = useState([]);
  const [sehirdekiKurumlar, setSehirdekiKurumlar] = useState([]);
  const olanSehirler = kurumlar.map((item) => item.sehir);

  useEffect(() => {
    kurumlisteler()
      .then((res, req) => {
        // console.log(res)
        setKurumlar(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const getKurumlarBySehir = (sehir) => {
    const kurumlarBySehir = kurumlar.filter((kurum) => kurum.sehir === sehir);
    setSehirdekiKurumlar(kurumlarBySehir);
    // console.log(sehirdekiKurumlar)
  };
  // console.log(sehirdekiKurumlar)

  const navigate = useNavigate();

  const formemail = useRef();
  const formfullname = useRef();
  const formpassword = useRef();
  const formphonenumber = useRef();
  const formTCNO = useRef();
  const formcompany = useRef();
  const formclass = useRef();
  const formcityoption = useRef();

  // console.log(sehirler)

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    TCNO: "",
    kurumAd: "",
    kurumID: "",
    password: "",
    studentClass: "",
  });

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData])


  const handleError = (err) =>
    toast.error(err, {
      position: "top-right",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const generalSettings = (() => {
    // GENEL VALIDATION
    !formfullname.current.value ? formfullname.current.style.borderColor = "red" : formfullname.current.style.borderColor = "#ced4da";
    !formemail.current.value ? formemail.current.style.borderColor = "red" : formemail.current.style.borderColor = "#ced4da";
    !formphonenumber.current.value ? formphonenumber.current.style.borderColor = "red" : formphonenumber.current.style.borderColor = "#ced4da";
    !formTCNO.current.value ? formTCNO.current.style.borderColor = "red" : formTCNO.current.style.borderColor = "#ced4da";
    !formpassword.current.value ? formpassword.current.style.borderColor = "red" : formpassword.current.style.borderColor = "#ced4da";
    !formcompany.current.value ? formcompany.current.style.borderColor = "red" : formcompany.current.style.borderColor = "#ced4da";
    !formclass.current.value ? formclass.current.style.borderColor = "red" : formclass.current.style.borderColor = "#ced4da";

    // PHONE NUMBER VALIDATION
    if (formphonenumber.current.value.length !== 11) {
      toast.error("Telefon Numarası 11 haneli olmalıdır !")
      formphonenumber.current.style.borderColor = "red"
    }
    else {
      formphonenumber.current.style.borderColor = "#ced4da"
    }

    // TCNO VALIDATION
    if (formTCNO.current.value.length !== 11) {
      toast.error("TC Kimlik No 11 haneli olmalıdır !")
      formTCNO.current.style.borderColor = "red"
    }
    else {
      formTCNO.current.style.borderColor = "#ced4da"
    }

    // PASSWORD VALIDATION
    if (formpassword.current.value.length < 6) {
      toast.error("Parola en az 6 karakterden oluşmalıdır !")
      formpassword.current.style.borderColor = "red"
    }
    else {
      formpassword.current.style.borderColor = "#ced4da"
    }

  })

  const errorCatch = (message) => {

    // EMAIL VALIDATION
    if (message === "Bu e-posta adresiyle bir hesap zaten mevcut. Lütfen başka bir e-posta adresi deneyin") {
      formemail.current.style.borderColor = "red";
      formpassword.current.value = "";
      formpassword.current.style.borderColor = "red";
    }

    // TC KİMLİK NO VALIDATION
    if (message === "Bu TC kimlik numarası ile bir hesap zaten mevcut") {
      formTCNO.current.style.borderColor = "red"
      formpassword.current.value = "";
      formpassword.current.style.borderColor = "red";
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    generalSettings();

    signup(formData)

      .then((res) => {
        const { message, success } = res.data;
        if (success) {
          // console.log("Başarılı")
          handleSuccess(message)
          setTimeout(() => {
            navigate("/panel/uyegiris");
          }, 1000);
        } else {
          handleError(message)
          // console.log(message)
          errorCatch(message)

        }
      })
      .catch((err) => {
        console.log(err)
      })

  };


  return (

    <div className="signupScreen">

      <form className="form-signup" onSubmit={handleSubmit}>

        <img src={acilogo} className="mb-4" alt="acilogo" height="72" />
        <h1 className="h3 mb-3 font-weight-normal">Üye Ol</h1>

        <div className="signup-email_username_pass_form">

          <div className="signup-forms">
            <input ref={formfullname} onChange={(e) => setFormData({ ...formData, fullname: e.target.value.toLocaleUpperCase("tr-TR") })} type="text" id="inputFullname" className="form-control" placeholder="Ad Soyad" required="" autoFocus="" />
            <i className="fa-solid fa-user signup-icons"></i>
          </div>

          <div className="signup-forms">
            <input ref={formemail} onChange={(e) => setFormData({ ...formData, email: e.target.value.toLowerCase() })} type="email" id="inputEmail" className="form-control" placeholder="E-Posta" required="" autoFocus="" />
            <i className="fa-solid fa-envelope signup-icons"></i>
          </div>

          <div className="signup-forms">
            <input ref={formphonenumber} onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })} type="number" id="inputPhoneNumber" className="form-control" placeholder="Telefon No" required="" autoFocus="" />
            <i className="fa-solid fa-phone signup-icons"></i>
          </div>

          <div className="signup-forms">
            <input ref={formTCNO} onChange={(e) => setFormData({ ...formData, TCNO: e.target.value })} type="number" id="inputTCNO" className="form-control" placeholder="TC Kimlik No" required="" autoFocus="" />
            <i className="fa-solid fa-address-card signup-icons"></i>
          </div>

          <div className="signup-forms">
            <input ref={formpassword} onChange={(e) => setFormData({ ...formData, password: e.target.value })} type="password" id="inputPassword" className="form-control" placeholder="Parola" required="" autoComplete="off"/>
            <i className="fa-sharp fa-solid fa-key signup-icons"></i>
          </div>


          <div className="signup-forms">
            <select defaultValue="" className="form-control" onChange={(e) => {
              setFormData({ ...formData, sehirID: e.target.value })

              const selectedSehir = e.target.value;
              getKurumlarBySehir(selectedSehir);

              // Kullanıcı yanlış bir şehir seçimi yapıp ve kurum seçerse  tekrardan şehir seçimi yaptığında seçtiği kurum sıfırlanır.
              setFormData({ ...formData, kurumAd: "", kurumID: "" });

              setSehirdekiKurumlar([]);
              getKurumlarBySehir(selectedSehir);
              formcompany.current.value = "";
              formcityoption.current.textContent = "Kurum Seç";
            }}>
              <option value="" disabled>Şehir Seç</option>
              {sehirler.map((iller) => {
                if (!olanSehirler.includes(iller.il_adi)) {
                  return null;
                }
                return (
                  <option key={iller.plaka}>{iller.il_adi}</option>
                )
              })}

            </select>
            <i className="fa-solid fa-city signup-icons"></i>
          </div>

          <div className="signup-forms">
            <select ref={formcompany} defaultValue="" className="form-control" onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const kurumAd = e.target.value;
              const kurumID = selectedOption.getAttribute("data-kurumid");
              setFormData({ ...formData, kurumAd, kurumID });
            }}>
              <option ref={formcityoption} value="" disabled>{sehirdekiKurumlar.length > 0 ? "Kurum Seç" : "Kurum seçmeden önce şehir seçiniz"}</option>
              {
                sehirdekiKurumlar ? sehirdekiKurumlar.map((kurum) => {
                  return (
                    <option disabled={kurum.isActive ? false : true} data-kurumid={kurum._id} key={kurum._id}>
                      {kurum.isActive ? kurum.kurumAdı : `${kurum.kurumAdı} (Kurum Aktif Değil)`}
                    </option>
                  )
                })
                  : null
              }


            </select>
            <i className="fa-solid fa-school signup-icons"></i>
          </div>

          <div className="signup-forms">
            <select ref={formclass} defaultValue="" className="form-control" onChange={(e) => {
              setFormData({ ...formData, studentClass: e.target.value })

            }}>
              <option value="" disabled>Sınıf Seç</option>
              {siniflar.map((sinif) => {
                return (
                  <option key={sinif.id}>{sinif.sinif}</option>
                )
              })}

            </select>
            <i className="fa-solid fa-list-ol signup-icons"></i>
          </div>



        </div>


        <button className="btn btn-lg btn-primary btn-block w-50 mt-4 mb-2" type="submit">Üye Ol</button>
        <div className="mt-3">
          <h6>Zaten bir hesabın varmı ?
            <Link className="ms-1" to={"/panel/uyegiris"}>
              Giriş Yap
            </Link>
          </h6>

        </div>

        <p className="mt-4 mb-0 text-muted">© 2024-2025</p>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SignupPage;