import { kurumlisteler, videocozumliste, userlisteler } from "../../axios/axios";
// actions.js
export const setVideoCozumListe = (data) => ({
    type: 'SET_VIDEO_COZUM_LISTE',
    payload: data,
  });
  
  export const setTumKurumListe = (data) => ({
    type: 'SET_TUM_KURUM_LISTE',
    payload: data,
  });

  export const setTumUserListe = (data) => ({
    type: 'SET_TUM_USER_LISTE',
    payload: data,
  });
  
  export const fetchVideoCozumListe = () => {
    return async (dispatch) => {
      try {
        const response = await videocozumliste();
        dispatch(setVideoCozumListe(response.data));
      } catch (error) {
        console.log(error);
      }
    };
  };
  
  export const fetchTumKurumListe = () => {
    return async (dispatch) => {
      try {
        const response = await kurumlisteler();
        dispatch(setTumKurumListe(response.data));
      } catch (error) {
        console.log(error);
      }
    };
  };

  export const fetchTumUserListe = () => {
    return async (dispatch) => {
      try {
        const response = await userlisteler();
        dispatch(setTumUserListe(response.data));
      } catch (error) {
        console.log(error);
      }
    };
  };
  // Diğer action'lar...
  