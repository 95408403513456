import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// BİLDİRİM İÇİN PAKET
import { ToastContainer, toast } from "react-toastify";

// AXİOS
import { login } from "../axios/axios.js";

// LOGOLAR VE CSS
import acilogo from "./img/aciegitimkurumlarilogo.png";
import "./css/LoginAndSignup/LoginScreen.css";

const LoginPage = () => {
  const navigate = useNavigate();

  const formemail = useRef();
  const formpassword = useRef();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  const handleError = (err) => {
    toast.error(err, {
      position: "bottom-left",
    });
  }

  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

      login(formData)
      .then((res)=>{
        const { message, success, user } = res.data;
        if (success) {
          handleSuccess(message);
          // console.log("başarılı")
          setTimeout(() => {
            navigate("/panel/admin")
            if (user.userType === 'ADMIN') {
              navigate('/panel/admin');
            } else if (user.userType === 'KURUM') {
              navigate('/panel/kurum');
            } else if (user.userType === 'OGRENCI') {
              navigate('/panel/ogrenci');
            }
          }, 1000);
        } else {
          handleError(message);
          if (message === "Tüm alanların doldurulması zorunludur!") {
            !formemail.current.value ? formemail.current.style.borderColor = "red" : formemail.current.style.borderColor = "#ced4da";
            !formpassword.current.value ? formpassword.current.style.borderColor = "red" : formpassword.current.style.borderColor = "#ced4da";
          }
          if (message === "Hatalı e-posta veya şifre") {
            // console.log("hatalı şifre veya e-posta")
            formpassword.current.value = "";
            formemail.current.style.borderColor = "red";
            formemail.current.placeholder = message;
            formpassword.current.style.borderColor = "red";
          }
        }
      }).catch((err)=>{
        console.log(err)
      })

    // setFormData({
    //   ...formData,
    //   email: "",
    //   password: "",
    // });
  };

  return (

    <div className="loginScreen">

      <form className="form-signin" onSubmit={handleSubmit}>

        <img src={acilogo} className="mb-4" alt="acilogo" height="72" />
        <h1 className="h3 mb-3 font-weight-normal">Giriş Yap</h1>

        <div className="email_pass_form">

          <div className="form-email">
            <input ref={formemail} onChange={(e) => setFormData({ ...formData, email: e.target.value })} type="email" id="inputEmail" className="form-control" placeholder="E-Posta" required="" autoFocus="" />
            <i className="fa-solid fa-envelope email-icon"></i>
          </div>


          <div className="form-pass">
            <input ref={formpassword} onChange={(e) => setFormData({ ...formData, password: e.target.value })} type="password" id="inputPassword" className="form-control" placeholder="Parola" required="" autoComplete="off"/>
            <i className="fa-sharp fa-solid fa-key passkey-icon"></i>
          </div>

        </div>


        <button className="btn btn-lg btn-primary btn-block w-50 mt-4 mb-2" type="submit">Giriş Yap</button>
        <div className="mt-3">
          <h6>Henüz bir hesabın yokmu ?
            <Link className="ms-1" to={"/panel/uyekayit"}>
              Üye Ol
            </Link>
          </h6>

        </div>

        <p className="mt-4 mb-0 text-muted">© 2024-2025</p>
      </form>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
