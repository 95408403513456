// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal{
    width: 100%;
    display: flex !important;
    justify-content: center;
 
}
.modal-content{
    background-color: #5c5c5c;
}

 /* ADMİN USER */

 .kurumUserAddModalDialog{
    width: 60%;
    max-width: 800px;
    color: rgb(255, 255, 255);
}
.kurumUserUpdateModalDialog{
    width: 90%;
    max-width: 800px;
    color: rgb(255, 255, 255);
}


/* VİDEO SOLUTİON MODEL */

.kurumVideoSolutionModalDialog{
    width: 80%;
    height: 100%;
    color: rgb(255, 255, 255);
    margin: 0;
    max-width: none;
    max-height: none;
    display: flex;
    align-items: center;
}

.kurumVideoSolutionModalDialog .modal-content {
    height: 80vh;
}

/* .modal sınıfının z-index değerini 99999 yapar */
.kurumVideoSolutionModalDialogModal {
    z-index: 99999;
}

.kurumVideoSolutionModalQuestionArea{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
}
.kurumVideoSolutionModalNextquestion{
    margin-left: 50px;
}
.kurumVideoSolutionModalIcon{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/Kurum/KurumModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAwB;IACxB,uBAAuB;;AAE3B;AACA;IACI,yBAAyB;AAC7B;;CAEC,eAAe;;CAEf;IACG,UAAU;IACV,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,yBAAyB;AAC7B;;;AAGA,yBAAyB;;AAEzB;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA,kDAAkD;AAClD;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".modal{\r\n    width: 100%;\r\n    display: flex !important;\r\n    justify-content: center;\r\n \r\n}\r\n.modal-content{\r\n    background-color: #5c5c5c;\r\n}\r\n\r\n /* ADMİN USER */\r\n\r\n .kurumUserAddModalDialog{\r\n    width: 60%;\r\n    max-width: 800px;\r\n    color: rgb(255, 255, 255);\r\n}\r\n.kurumUserUpdateModalDialog{\r\n    width: 90%;\r\n    max-width: 800px;\r\n    color: rgb(255, 255, 255);\r\n}\r\n\r\n\r\n/* VİDEO SOLUTİON MODEL */\r\n\r\n.kurumVideoSolutionModalDialog{\r\n    width: 80%;\r\n    height: 100%;\r\n    color: rgb(255, 255, 255);\r\n    margin: 0;\r\n    max-width: none;\r\n    max-height: none;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.kurumVideoSolutionModalDialog .modal-content {\r\n    height: 80vh;\r\n}\r\n\r\n/* .modal sınıfının z-index değerini 99999 yapar */\r\n.kurumVideoSolutionModalDialogModal {\r\n    z-index: 99999;\r\n}\r\n\r\n.kurumVideoSolutionModalQuestionArea{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-bottom: 20px;\r\n    text-align: center;\r\n}\r\n.kurumVideoSolutionModalNextquestion{\r\n    margin-left: 50px;\r\n}\r\n.kurumVideoSolutionModalIcon{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
