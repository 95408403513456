// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signupScreen{
    height: 100vh;
    background: #4568DC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #B06AB3, #4568DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.form-signup{
    padding: 30px;
    background-color: aliceblue;
    width: 50%;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.signup-form-control{
    margin-top: 10px;
    padding-left: 40px;
}
.signup-forms{
    position: relative;
}
.signup-icons{
    position: absolute;
    left: 10px;
    top: 9px;
    font-size: 20px;
}
@media only screen and (max-width: 991px) {
    .form-signup{
      width: 80%;
    }
     }
     @media only screen and (max-height: 740px) {
        .signupScreen{
          height: 100%;
        }
         }`, "",{"version":3,"sources":["webpack://./src/pages/css/LoginAndSignup/SignupScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,GAAG,8BAA8B,GACc,+BAA+B;IACjG,uDAAuD,EAAE,qEAAqE;IAC9H,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,2BAA2B;IAC3B,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,eAAe;AACnB;AACA;IACI;MACE,UAAU;IACZ;KACC;KACA;QACG;UACE,YAAY;QACd;SACC","sourcesContent":[".signupScreen{\r\n    height: 100vh;\r\n    background: #4568DC;  /* fallback for old browsers */\r\n    background: -webkit-linear-gradient(to right, #B06AB3, #4568DC);  /* Chrome 10-25, Safari 5.1-6 */\r\n    background: linear-gradient(to right, #B06AB3, #4568DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n.form-signup{\r\n    padding: 30px;\r\n    background-color: aliceblue;\r\n    width: 50%;\r\n    border-radius: 10px;\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n}\r\n.signup-form-control{\r\n    margin-top: 10px;\r\n    padding-left: 40px;\r\n}\r\n.signup-forms{\r\n    position: relative;\r\n}\r\n.signup-icons{\r\n    position: absolute;\r\n    left: 10px;\r\n    top: 9px;\r\n    font-size: 20px;\r\n}\r\n@media only screen and (max-width: 991px) {\r\n    .form-signup{\r\n      width: 80%;\r\n    }\r\n     }\r\n     @media only screen and (max-height: 740px) {\r\n        .signupScreen{\r\n          height: 100%;\r\n        }\r\n         }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
