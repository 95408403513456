// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoSolutionModalHeader{
    height: 8vh;
}
.videoSolutionModalBody{
    height: 62vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videoSolutionModalFooter{
    height: 8vh;
    margin-bottom: 20px;
}
.search-and-add-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
    .companyListAddButton{
        width: 14rem;
        height: 50px;
        margin-top: 20px;
    }
    .userListAddButton{
        width: 14rem;
        height: 50px;
        margin-top: 20px;
    }
   }
   @media only screen and (max-width: 512px) {
    .search-and-add-area{
        justify-content: center;
    }
    .companyListUpdateButton{
        width: 14rem;
        height: 50px;
        margin-top: 20px;
    }
    .userListUpdateButton{
        width: 14rem;
        height: 50px;
        margin-top: 20px;
    }
   }

   @media only screen and (max-width: 512px) {
    .headerOptionAlan{
        justify-content: center;
        flex-wrap: wrap;
    }
   }
   
   `, "",{"version":3,"sources":["webpack://./src/pages/css/Public/public.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,gBAAgB;IACpB;IACA;QACI,YAAY;QACZ,YAAY;QACZ,gBAAgB;IACpB;GACD;GACA;IACC;QACI,uBAAuB;IAC3B;IACA;QACI,YAAY;QACZ,YAAY;QACZ,gBAAgB;IACpB;IACA;QACI,YAAY;QACZ,YAAY;QACZ,gBAAgB;IACpB;GACD;;GAEA;IACC;QACI,uBAAuB;QACvB,eAAe;IACnB;GACD","sourcesContent":[".videoSolutionModalHeader{\r\n    height: 8vh;\r\n}\r\n.videoSolutionModalBody{\r\n    height: 62vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.videoSolutionModalFooter{\r\n    height: 8vh;\r\n    margin-bottom: 20px;\r\n}\r\n.search-and-add-area{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n@media only screen and (max-width: 991px) {\r\n    .companyListAddButton{\r\n        width: 14rem;\r\n        height: 50px;\r\n        margin-top: 20px;\r\n    }\r\n    .userListAddButton{\r\n        width: 14rem;\r\n        height: 50px;\r\n        margin-top: 20px;\r\n    }\r\n   }\r\n   @media only screen and (max-width: 512px) {\r\n    .search-and-add-area{\r\n        justify-content: center;\r\n    }\r\n    .companyListUpdateButton{\r\n        width: 14rem;\r\n        height: 50px;\r\n        margin-top: 20px;\r\n    }\r\n    .userListUpdateButton{\r\n        width: 14rem;\r\n        height: 50px;\r\n        margin-top: 20px;\r\n    }\r\n   }\r\n\r\n   @media only screen and (max-width: 512px) {\r\n    .headerOptionAlan{\r\n        justify-content: center;\r\n        flex-wrap: wrap;\r\n    }\r\n   }\r\n   \r\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
