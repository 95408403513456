// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kurumProfileCol {
    background-color: white;
    padding: 40px;
    border-radius: 2px;
    color: black;
}

.kurumProfileSelect {
    background-color: #c9c9c9;
}

.kurumProfileInput {
    background-color: #c9c9c9;
}

.kurumProfileVideoSolutionLabel {
    display: block;
    width: 100%;
}
.kurumProfileVideoSolutionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}
.kurumProfileVideoSolution {
    display: flex; 
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    padding: 2px;
    padding-left: 5px; 
}
.kurumProfileVideoSolutionLabel{
    width: 80%;
    margin-right: 20px ;
    white-space: normal;
}
.kurumProfileVideoSolutionSwitch{
    width: 20%;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/Kurum/KurumCompanyProfile.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd","sourcesContent":[".kurumProfileCol {\r\n    background-color: white;\r\n    padding: 40px;\r\n    border-radius: 2px;\r\n    color: black;\r\n}\r\n\r\n.kurumProfileSelect {\r\n    background-color: #c9c9c9;\r\n}\r\n\r\n.kurumProfileInput {\r\n    background-color: #c9c9c9;\r\n}\r\n\r\n.kurumProfileVideoSolutionLabel {\r\n    display: block;\r\n    width: 100%;\r\n}\r\n.kurumProfileVideoSolutionDiv{\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n}\r\n.kurumProfileVideoSolution {\r\n    display: flex; \r\n    border: 1px solid rgb(0, 0, 0);\r\n    border-radius: 5px;\r\n    padding: 2px;\r\n    padding-left: 5px; \r\n}\r\n.kurumProfileVideoSolutionLabel{\r\n    width: 80%;\r\n    margin-right: 20px ;\r\n    white-space: normal;\r\n}\r\n.kurumProfileVideoSolutionSwitch{\r\n    width: 20%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
