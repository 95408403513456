import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { userupdate } from "../../../../axios/axios";

// SELECT OPTİON'DA KULLANILAN İLLER
import siniflar from "../../../../service/siniflar.json";
// MODAL CSS
import "../../../css/Kurum/KurumModal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons';


const KurumStudentUpdate = (props) => {


  // console.log(props)

  const [fullnameVal, setFullnameVal] = useState();
  const [emailVal, setEmailVal] = useState();
  const [phoneNumberVal, setPhoneNumberVal] = useState();
  const [TCNOVal, setTCNOVal] = useState();
  const [kurumAdVal, setKurumAdVal] = useState();
  const [kurumIDVal, setKurumIDVal] = useState();
  const [passwordVal, setPasswordVal] = useState();
  const [studentClassVal, setStudentClassVal] = useState();
  const [userTypeVal, setUserTypeVal] = useState();
  const [isActiveVal, setIsActiveVal] = useState();

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };


  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [updatedFormData, setUpdatedFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    TCNO: "",
    kurumAd: "",
    kurumID: "",
    password: "",
    studentClass:"",
    userType: "",
    isActive: false,
  });


  useEffect(() => {
    // console.log(props);
    const updateBilgiler = props.updateBilgiler;

    if (updateBilgiler !== undefined) {
      setFullnameVal(updateBilgiler.fullname);
      setEmailVal(updateBilgiler.email);
      setPhoneNumberVal(updateBilgiler.phoneNumber);
      setTCNOVal(updateBilgiler.TCNO);
      setKurumAdVal(updateBilgiler.kurumAd);
      setKurumIDVal(updateBilgiler.kurumID);
      setPasswordVal(updateBilgiler.password);
      setStudentClassVal(updateBilgiler.studentClass);
      setUserTypeVal(updateBilgiler.userType);
      setIsActiveVal(updateBilgiler.isActive);
    }
  }, [props.updateBilgiler]);


  return (
    <>
      <FontAwesomeIcon style={{ fontSize: "20px" }} onClick={handleShow} className="me-3" icon={faCog} size="1x" />

      <Modal dialogClassName="modal-dialog kurumUserUpdateModalDialog" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcı Güncelleme</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // console.log(updatedFormData)
                    userupdate(props.updateBilgiler._id, updatedFormData)
                      .then((res) => {
                        handleClose()

                        toast.success("Kullanıcı Güncelleme Başarılı !")
                        // console.log(res.data)
                      })
                      .catch((error) => {
                        if (error.response.data.message.includes("E11000")) {
                          const match = error.response.data.message.match(/\{([^}]+)\}/);
                          const duplicateKeyObject = match ? match[0] : "{}";
                          const errorMessage = `Bu değer zaten kullanımda: ${duplicateKeyObject}`;
                          // console.log(error)
                          toast.error(errorMessage);
                        } else {
                          toast.error(error.response.data.message);
                          // console.log(error)
                        }
                      });

                  }}
                >


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Ad Soyad</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFullnameVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, fullname: e.target.value })
                      }
                      type="name"
                      placeholder="Ad Soyad Giriniz"
                      value={fullnameVal || ""}
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-Posta</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setEmailVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, email: e.target.value })
                      }
                      type="email"
                      placeholder="E-Posta adresinizi giriniz"
                      value={emailVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>Telefon No</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setPhoneNumberVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, phoneNumber: e.target.value })
                      }
                      type="text"
                      placeholder="Telefon numaranızı giriniz"
                      value={phoneNumberVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicTC">
                    <Form.Label>TC Kimlik No</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setTCNOVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, TCNO: e.target.value })
                      }
                      type="text"
                      placeholder="Tc Kimlik no giriniz"
                      value={TCNOVal || ""}
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label>Kurum Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, kurumAd: e.target.value })
                      }
                      type="text"
                      readOnly
                      placeholder="Kurum Adı Giriniz"
                      value={kurumAdVal || ""}
                    />

                  </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicSınıf">
                  <Form.Label>Sınıf</Form.Label>
                  <Form.Select defaultValue="" className="ps-5" onChange={(e) => {
                    setUpdatedFormData({ ...updatedFormData, studentClass: e.target.value })
                  }
                  }>
                    <option value="" disabled>{studentClassVal}</option>
                    {siniflar.map((sinif) => {
                      return (
                        <option key={sinif.id}>{sinif.sinif}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Parola</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setPasswordVal(e.target.value) +
                        setUpdatedFormData({ ...updatedFormData, password: e.target.value })
                      }
                      type="password"
                      placeholder="Parolanızı değiştirmek için yeni parola giriniz"
                      autoComplete="off"
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicUserType">
                    <Form.Label>Kullanıcı Türü</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, userType: e.target.value })
                      }
                      type="text"
                      readOnly
                      placeholder="Kullanıcı Türü Giriniz"
                      value={userTypeVal || ""}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formUserActive">
                    <Form.Label>Kullanıcı Durumu</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setIsActiveVal(e.target.value) +
                      setUpdatedFormData({ ...updatedFormData, isActive: e.target.value })
                    }>
                      <option value="" disabled>{isActiveVal === true ? "Aktif" : "Pasif"}</option>
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>
                    </Form.Select>
                  </Form.Group>




                  <Form.Group className="d-grid">


                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Güncelle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );

};
export default KurumStudentUpdate;
