import React from "react";
import styled from "styled-components";

// !!! İCONLAR !!!

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faX, faArrowRotateRight, } from '@fortawesome/free-solid-svg-icons';
import KurumStudentAdd from "./KurumStudentAdd";
import "../../../css/Kurum/KurumDataTableFilterComponent.css";

// SERVICE
import siniflar from "../../../../service/siniflar.json"

// AXIOS
import { usermultipleisactiveupdate } from "../../../../axios/axios";

// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const KurumStudentManageFilterComponent = ({ userData, filterText, onFilter, onClear,selectedMultipleUpdatedItems, setKayitBasarili,setSecilenOgrenciSinif, setSecilenKullaniciTur, setSecilenKullaniciDurum }) => {

  const multipleUpdate = (()=>{
    // console.log(selectedMultipleUpdatedItems)

    usermultipleisactiveupdate(selectedMultipleUpdatedItems)
    .then((res) => {
      // console.log(res.data.result.modifiedCount)
      const userUpdateNumber = res.data.result.modifiedCount; // Kaç kullanıcı güncellendi
      const { success } = res.data;
      if (success) {
        toast.success(`${userUpdateNumber} Öğrenci başarıyla güncellendi !`);
      }
      else {
        toast.danger("Öğrenciler güncellenemedi !")

      }

    })
    .catch((err)=>{
      // console.log(err)
    });

  })


  return (

    <div style={{ width: "100%" }}>


      <div className="search-and-add-area">
        <div className="kurumSearchFilterAlan">
          <FontAwesomeIcon className="kurumHeaderSearchIcon" icon={faSearch}></FontAwesomeIcon>
          <Input
            id="search"
            type="text"
            placeholder="Tabloda Ara..."
            className="kurumHeaderFilter"
            value={filterText}
            onChange={onFilter}
          />
          <ClearButton className="kurumHeaderClear" onClick={onClear}><FontAwesomeIcon icon={faX} /></ClearButton>

        </div>

        <div>
        <button className="btn btn-primary multipleIsActiveUpdateButton companyListUpdateButton" onClick={multipleUpdate}>
          <FontAwesomeIcon className="me-3" icon={faArrowRotateRight}></FontAwesomeIcon>
            <p style={{display:"inline", fontFamily:"sans-serif", fontSize:"18px"}}>Durum Güncelle</p>
            </button>
        </div>

        <div>
          <KurumStudentAdd userData={userData} setKayitBasarili={setKayitBasarili} ></KurumStudentAdd>
        </div>
      </div>


      <div className="kurumHeaderOptionAlan mt-3">

        <select
          defaultValue="ogrencisinifsec"
          onChange={(e) =>
            e.target.value === "ogrencisinifsec"
              ?
              setSecilenOgrenciSinif("")
              :
              setSecilenOgrenciSinif(e.target.value)
          }
          className="kurumHeaderSelect "
        >
          <option value="ogrencisinifsec" >Öğrenci Sınıf Seç</option>
          {siniflar.map((sinif)=>{
            return(
              <option key={sinif.id} value={sinif.sinif}>{sinif.sinif}</option>
            )
          })}

        </select>

        <select
          defaultValue={"kullanicidurumsec"}
          onChange={(e) =>
            e.target.value === "kullanicidurumsec"
              ?
              setSecilenKullaniciDurum("")
              :
              setSecilenKullaniciDurum(e.target.value)
          }
          className="kurumHeaderSelect"
        >
          <option value="kullanicidurumsec">Kullanıcı Durum Seç</option>
          <option value="true">Aktif</option>
          <option value="false">Pasif</option>
        </select>

      </div>

    </div>

  )
};

export default KurumStudentManageFilterComponent;
