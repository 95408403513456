import React, { useEffect } from "react";
import Table from "./Table/CompanyTable/CompanyDataTable";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { fetchVideoCozumListe, fetchTumKurumListe } from '../../store/actions/actions';


const AdminCompany = () => {

  // console.log("admincompanny Girişti !!");

  const updateClickHandler = (updateAlan) => {
    // console.log("Güncelle", updateAlan);
  }

  const deleteClickHandler = deleteAlan => {
    // console.log("Sil", deleteAlan);
  }

  // Redux'tan state çekme

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideoCozumListe());
    dispatch(fetchTumKurumListe());
  }, [dispatch]);

  return (
    <>
      <Table updateClickHandler={updateClickHandler} deleteClickHandler={deleteClickHandler} />
      <ToastContainer />
    </>
  );
};

export default AdminCompany;
