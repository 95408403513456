import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Button, Row, Col, FormCheck } from "react-bootstrap";
import { kurumupdate, profilkurumbilgigetir } from "../../axios/axios";

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideoCozumListe } from '../../store/actions/actions';

// SELECT OPTİON'DA KULLANILAN İLLER
import sehirler from "../../service/iller.json";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CSS
import "../css/Admin/AdminProfile.css"


const AdminCompanyProfile = (props) => {
    // console.log(props)

    const [kurumAdıVal, setKurumAdıVal] = useState('');
    const [sehirVal, setSehirVal] = useState('');
    const [telefonNoVal, setTelefonNoVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    const [kurumTurVal, setKurumTurVal] = useState('');
    const [kurumAdresVal, setKurumAdresVal] = useState('');
    const [isActiveVal, setIsActiveVal] = useState('');
    const [pathsVal, setPathsVal] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const [profileCompanyInfo, setProfileCompanyInfo] = useState([]);

    const videoCozumListe = useSelector(state => state.videoCozumListe);

    const filteredVideoCozumListe = videoCozumListe
  .sort((a, b) => {
    // Path değerlerini parçalarına ayır
    const aParts = a.path.match(/(\d+)|([^\d]+)/g);
    const bParts = b.path.match(/(\d+)|([^\d]+)/g);

    // Parçaları karşılaştır
    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aPart = aParts[i];
      const bPart = bParts[i];

      // Sayısal kısımları sayısal olarak karşılaştır
      if (!isNaN(aPart) && !isNaN(bPart)) {
        const numA = parseInt(aPart, 10);
        const numB = parseInt(bPart, 10);
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        // Metin kısımları alfabetik olarak küçük harflerle karşılaştır
        const lowerA = aPart.toLowerCase();
        const lowerB = bPart.toLowerCase();
        if (lowerA !== lowerB) {
          return lowerA.localeCompare(lowerB);
        }
      }
    }
    // Eğer tüm parçalar eşitse, uzunluklarına göre karşılaştır
    return aParts.length - bParts.length;
  });

    const phoneNumberRef = useRef();

    // Redux'tan state çekme

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVideoCozumListe());
    }, [dispatch]);

    //MODAL DEĞİŞKENLERİ
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    useEffect(() => {
        profilkurumbilgigetir(props.userData.kurumID)
            .then((res) => {
                // console.log(res.data)
                setProfileCompanyInfo(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // console.log(profileCompanyInfo)
    // console.log(props.userData.kurumID)

    // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
    const [updatedFormData, setUpdatedFormData] = useState({
        kurumAdı: "",
        sehir: "",
        telefonNo: "",
        email: "",
        kurumTur: "",
        kurumAdres: "",
        isActive: false,
        paths: null,
    });

    useEffect(() => {
        // console.log(updatedFormData)
    }, [updatedFormData])

    useEffect(() => {
        if (profileCompanyInfo !== undefined) {
            setKurumAdıVal(profileCompanyInfo.kurumAdı)
            setSehirVal(profileCompanyInfo.sehir)
            setTelefonNoVal(profileCompanyInfo.telefonNo)
            setEmailVal(profileCompanyInfo.email)
            setKurumTurVal(profileCompanyInfo.kurumTur)
            setKurumAdresVal(profileCompanyInfo.kurumAdres)
            setIsActiveVal(profileCompanyInfo.isActive);
            setPathsVal(profileCompanyInfo.paths);
            setCheckedItems(profileCompanyInfo.paths); // Burada güncelleme yapılıyor
        }
    }, [profileCompanyInfo])


    return (
        <>
            <Container>
                <Row>
                    <h3 style={{textAlign:"center"}}>Kurum Profil</h3>
                </Row>
                <Row className="justify-content-center">
                    <Col className="adminProfileCol" xs={12} md={9}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();

                                if (phoneNumberRef.current.value.length !== 11) {
                                    toast.error("Telefon Numarası 11 haneli olmalıdır.");
                                }
                                else {
                                    kurumupdate(profileCompanyInfo._id, updatedFormData)
                                        .then((res) => {
                                            handleClose()

                                            toast.success("Kurum Güncelleme Başarılı !")
                                            // console.log(res.data)
                                        })
                                        .catch((err) => toast.error("Kurum Güncelleme Başarısız", err.response.data.message));
                                }
                            }}
                        >

                            <Form.Group className="mb-3" controlId="formBasicCompanyName">
                                <Form.Label>Kurum Adı</Form.Label>
                                <Form.Control
                                    onChange={(e) =>
                                        setKurumAdıVal(e.target.value) +
                                        setUpdatedFormData({ ...updatedFormData, kurumAdı: e.target.value.toLocaleUpperCase("tr-TR") })
                                    }
                                    type="name"
                                    placeholder="Kurum Adı Giriniz"
                                    value={kurumAdıVal}
                                    className="adminProfileInput"
                                />
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicCity">
                                <Form.Label>Şehir</Form.Label>
                                <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) =>
                                    setSehirVal(e.target.value) +
                                    setUpdatedFormData({ ...updatedFormData, sehir: e.target.value })
                                }>
                                    <option value="" disabled>{sehirVal}</option>
                                    {sehirler.map((iller) => {
                                        return (
                                            <option key={iller.plaka}>{iller.il_adi}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                                <Form.Label>Telefon No</Form.Label>
                                <Form.Control
                                    onChange={(e) =>
                                        setTelefonNoVal(e.target.value) +
                                        setUpdatedFormData({ ...updatedFormData, telefonNo: e.target.value })
                                    }
                                    type="number"
                                    placeholder="Telefon numaranızı giriniz"
                                    value={telefonNoVal}
                                    ref={phoneNumberRef}
                                    className="adminProfileInput"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-Posta</Form.Label>
                                <Form.Control
                                    onChange={(e) =>
                                        setEmailVal(e.target.value) +
                                        setUpdatedFormData({ ...updatedFormData, email: e.target.value.toLowerCase() })
                                    }
                                    type="email"
                                    placeholder="E-Posta adresinizi giriniz"
                                    value={emailVal}
                                    className="adminProfileInput"
                                />
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicCompanyType">
                                <Form.Label>Kurum Türü</Form.Label>
                                <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) =>
                                    setKurumTurVal(e.target.value) +
                                    setUpdatedFormData({ ...updatedFormData, kurumTur: e.target.value })
                                }>
                                    <option value="" disabled>{kurumTurVal}</option>
                                    <option>Kolej</option>
                                    <option>Kurs</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCompanyAdress">
                                <Form.Label>Kurum Adresi</Form.Label>
                                <Form.Control
                                    onChange={(e) =>
                                        setKurumAdresVal(e.target.value) +
                                        setUpdatedFormData({ ...updatedFormData, kurumAdres: e.target.value })
                                    }
                                    as="textarea"
                                    rows={3}
                                    placeholder="Adres giriniz"
                                    value={kurumAdresVal}
                                    className="adminProfileInput"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCompanyActive">
                                <Form.Label>Kurum Durumu</Form.Label>
                                <Form.Select defaultValue="" className="ps-5 adminProfileSelect" onChange={(e) =>
                                    setIsActiveVal(e.target.value) +
                                    setUpdatedFormData({ ...updatedFormData, isActive: e.target.value })
                                }>
                                    <option value="" disabled>{isActiveVal === true ? "Aktif" : "Pasif"}</option>
                                    <option value="true">Aktif</option>
                                    <option value="false">Pasif</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 adminProfileVideoSolutionGroup" controlId="formBasicCompanyActive">
                                <Form.Label className="adminProfileVideoSolutionLabel">Kurum Video Çözümleri</Form.Label>
                                <div className="adminProfileVideoSolutionDiv">
                                {filteredVideoCozumListe.map((videocozum) => {
                                    return (
                                        <Col xs={6} sm={4} md={4} xxl={3}
                                            className="adminProfileVideoSolution"
                                            key={videocozum.id}>

                                            <Form.Label className="adminProfileVideoSolutionLabel">{videocozum.path}</Form.Label>
                                            <FormCheck
                                                value={videocozum.id}
                                                className="adminProfileVideoSolutionSwitch"
                                                type="switch"
                                                id="custom-switch"
                                                checked={checkedItems && checkedItems.includes(Number(videocozum.id))}
                                                onChange={(e) => {
                                                    let newCheckedItems;
                                                    if (e.target.checked) {
                                                        newCheckedItems = [...checkedItems, Number(videocozum.id)];
                                                    } else {
                                                        newCheckedItems = checkedItems.filter(item => item !== Number(videocozum.id));
                                                    }
                                                    setCheckedItems(newCheckedItems);
                                                    setUpdatedFormData({ ...updatedFormData, paths: newCheckedItems });

                                                }}
                                            />
                                        </Col>
                                    )
                                })}
                                </div>

                            </Form.Group>



                            <Form.Group className="d-grid">


                                <Button variant="primary" type="submit" className="mt-4 w-100">
                                    Güncelle
                                </Button>

                                <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                                    Vazgeç
                                </Button>

                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );

};
export default AdminCompanyProfile;
