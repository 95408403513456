// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.cardAlann{
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 90vh;
}
.cardAlanLink {
    text-decoration: none;
    width: 23%;
}
.cardAlan-cardd {
    align-items: center;
    border: none;
    border-radius: 15px;
    display: flex;
    height: 180px;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}
.cardAlan-cardd p{
    color: #000;
    font-size: 22px;
    margin: 0;
}
.cardAlannStudentInfoDiv{
    display: flex;
    justify-content: space-around;
}
.cardAlannVideoSolutionInfoDiv{
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .cardAlannStudentInfoDiv{
    flex-direction: column;
  }
  .cardAlannVideoSolutionInfoDiv{
    flex-direction: column;
  }
  .cardAlanLink{
    width: 100%;
  }
     }`, "",{"version":3,"sources":["webpack://./src/pages/css/Kurum/kurumHome.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,WAAW;AACf;AACA;IACI,WAAW;IACX,eAAe;IACf,SAAS;AACb;AACA;IACI,aAAa;IACb,6BAA6B;AACjC;AACA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;KACG","sourcesContent":["\r\n.cardAlann{\r\n    border-radius: 20px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-evenly;\r\n    width: 100%;\r\n    height: 90vh;\r\n}\r\n.cardAlanLink {\r\n    text-decoration: none;\r\n    width: 23%;\r\n}\r\n.cardAlan-cardd {\r\n    align-items: center;\r\n    border: none;\r\n    border-radius: 15px;\r\n    display: flex;\r\n    height: 180px;\r\n    justify-content: space-between;\r\n    padding: 20px;\r\n    width: 100%;\r\n}\r\n.cardAlan-cardd p{\r\n    color: #000;\r\n    font-size: 22px;\r\n    margin: 0;\r\n}\r\n.cardAlannStudentInfoDiv{\r\n    display: flex;\r\n    justify-content: space-around;\r\n}\r\n.cardAlannVideoSolutionInfoDiv{\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n  .cardAlannStudentInfoDiv{\r\n    flex-direction: column;\r\n  }\r\n  .cardAlannVideoSolutionInfoDiv{\r\n    flex-direction: column;\r\n  }\r\n  .cardAlanLink{\r\n    width: 100%;\r\n  }\r\n     }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
