import React from "react";

// AXİOS
import { userdelete } from "../../../../axios/axios";

// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const AdminUserDelete = (props) => {

    const handleDelete = async (id, fullname) => {
        const confirmDelete = window.confirm(`${fullname} adlı kullanıcıyı silmek istediğinize emin misiniz ?`);
        if (confirmDelete) {
            userdelete(id)
                .then((res) => {
                    toast.success("Kullanıcı Başarıyla Silindi !")
                    console.log(res.data)
                })
                .catch((err) => toast.error("Kullanıcı Silme Başarısız !", err.response.data.message));
        }
    }

    return (
        <>
            <FontAwesomeIcon style={{ fontSize: "20px" }}
                onClick={() => handleDelete(props.deleteBilgiler._id, props.deleteBilgiler.fullname)}
                className="me-3"
                icon={faTrash}
                size="1x" />
        </>
    );

};
export default AdminUserDelete;
