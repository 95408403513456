// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    /* text-align: center; */
  }
  
  .rdt_Table {
    margin-bottom: 1rem;
  }
  .rdt_TableRow {
    background-color: grey;
  }
  .rdt_TableCol {
    color: black;
    font-weight: bolder;
  }
  .rdt_TableCol_Sortable {
  }
  .rdt_TableCell {
  }
  .rdt_TableHeader {
    background-color: transparent;
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    background-color: green;
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }
  
 `, "",{"version":3,"sources":["webpack://./src/pages/kurum/Table/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;EACA;EACA;EACA;EACA;IACE,6BAA6B;EAC/B;EACA;EACA;EACA;EACA;EACA;IACE,uBAAuB;EACzB;EACA;EACA;EACA;EACA","sourcesContent":[".App {\r\n    font-family: sans-serif;\r\n    /* text-align: center; */\r\n  }\r\n  \r\n  .rdt_Table {\r\n    margin-bottom: 1rem;\r\n  }\r\n  .rdt_TableRow {\r\n    background-color: grey;\r\n  }\r\n  .rdt_TableCol {\r\n    color: black;\r\n    font-weight: bolder;\r\n  }\r\n  .rdt_TableCol_Sortable {\r\n  }\r\n  .rdt_TableCell {\r\n  }\r\n  .rdt_TableHeader {\r\n    background-color: transparent;\r\n  }\r\n  .rdt_TableFooter {\r\n  }\r\n  .rdt_TableHead {\r\n  }\r\n  .rdt_TableHeadRow {\r\n    background-color: green;\r\n  }\r\n  .rdt_TableBody {\r\n  }\r\n  .rdt_ExpanderRow {\r\n  }\r\n  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
