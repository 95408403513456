// reducers.js
const initialState = {
  videoCozumListe: [],
  tumKurumListe: [],
  tumUserListe: [],
  // Diğer state alanları...
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VIDEO_COZUM_LISTE':
      return { ...state, videoCozumListe: action.payload };
    case 'SET_TUM_KURUM_LISTE':
      return { ...state, tumKurumListe: action.payload };
    case 'SET_TUM_USER_LISTE':
      return { ...state, tumUserListe: action.payload };
    // Diğer case'ler...
    default:
      return state;
  }
};

export default rootReducer;
