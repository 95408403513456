import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useEffect, useState } from "react";

import { profilkurumbilgigetir } from "../../axios/axios";

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideoCozumListe, fetchTumKurumListe, fetchTumUserListe } from '../../store/actions/actions';

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlay, faUserGraduate, faVideo, faX,faBuilding } from '@fortawesome/free-solid-svg-icons';

import "../css/Kurum/kurumHome.css";

const Home = ({userData}) => {
  const [profileCompanyInfo, setProfileCompanyInfo] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideoCozumListe());
    dispatch(fetchTumKurumListe());
    dispatch(fetchTumUserListe());
  }, [dispatch]);


  useEffect(() => {
    profilkurumbilgigetir(userData.kurumID)
      .then((res) => {
        // console.log(res.data)
        setProfileCompanyInfo(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])



  const tumUserListe = useSelector(state => state.tumUserListe);
  const tumKurumListe = useSelector(state => state.tumKurumListe);
  const videoCozumListe = useSelector(state => state.videoCozumListe);

  // console.log(tumUserListe)



  const ogrenciData = tumUserListe.filter((userliste) => {
    // oğrencinin kurumID'si, profileCompanyInfo._id ile aynı mı kontrol et aynı ise ve OGRENCI ise ogrenciData'ya kaydet
    return userliste.userType === "OGRENCI";
  });

  // console.log(ogrenciData)

  let aktifOgrenci = 0;
  let pasifOgrenci = 0;

  ogrenciData.forEach((ogrenci) => {
    ogrenci.isActive ? aktifOgrenci++ : pasifOgrenci++;
  });

  // console.log("Aktif Öğrenci Sayısı:", aktifOgrenci);
  // console.log("Pasif Öğrenci Sayısı:", pasifOgrenci);

  const kurumData = tumUserListe.filter((userliste) => {
    // oğrencinin kurumID'si, profileCompanyInfo._id ile aynı mı kontrol et aynı ise ve OGRENCI ise ogrenciData'ya kaydet
    return userliste.userType === "KURUM";
  });

  let aktifKurum = 0;
  let pasifKurum = 0;

  kurumData.forEach((kurum)=>{
    kurum.isActive ? aktifKurum++ : pasifKurum++;
  })

  // console.log(kurumData)

  // console.log(tumUserListe)
  // console.log(tumKurumListe)
  // console.log(videoCozumListe)

  // console.log(ogrenciData)
  // console.log(userData)
  // console.log(profileCompanyInfo)

  return (
    <>
      <div className="cardAlann">

        <div className="cardAlannStudentInfoDiv">
          <Link className="cardAlanLink" to="/panel/admin/kullanici">
            <div className="card cardAlan-cardd bg-none">
              <FontAwesomeIcon icon={faUserGraduate} size="2x" />
              <p><strong>{ogrenciData.length}</strong></p>
              <p>Toplam Öğrenci</p>
            </div>
          </Link>

          <Link className="cardAlanLink" to="/panel/admin/kullanici">
            <div className="card cardAlan-cardd bg-success">
              <FontAwesomeIcon icon={faCheck} size="2x" />
              <p><strong>{aktifOgrenci}</strong></p>
              <p>Onaylanmış Öğrenci</p>
            </div>
          </Link>

          <Link className="cardAlanLink" to="/panel/admin/kullanici">
            <div className="card cardAlan-cardd bg-danger">
              <FontAwesomeIcon icon={faX} size="2x" />
              <p><strong>{pasifOgrenci}</strong></p>
              <p>Onaylanmamış Öğrenci</p>
            </div>
          </Link>
        </div>

        <div className="cardAlannVideoSolutionInfoDiv">

<Link className="cardAlanLink" to="/panel/admin/kullanici">
    <div className="card cardAlan-cardd bg-none">
      <FontAwesomeIcon icon={faBuilding} size="2x" />
      <p><strong>{kurumData.length}</strong></p>
      <p>Toplam Kurum</p>
    </div>
  </Link>

  <Link className="cardAlanLink" to="/panel/admin/kullanici">
    <div className="card cardAlan-cardd bg-success">
      <FontAwesomeIcon icon={faVideo} size="2x" />
      <p><strong>{aktifKurum}</strong></p>
      <p>Aktif Kurum</p>
    </div>
  </Link>

  <Link className="cardAlanLink" to="/panel/admin/kullanici">
    <div className="card cardAlan-cardd bg-danger">
      <FontAwesomeIcon icon={faPlay} size="2x" />
      <p><strong>{pasifKurum}</strong></p>
      <p>Pasif Kurum</p>
    </div>
  </Link>

</div>

        <div className="cardAlannVideoSolutionInfoDiv">

          <Link className="cardAlanLink" to="/panel/admin/videocozum">
            <div className="card cardAlan-cardd bg-none">
              <FontAwesomeIcon icon={faVideo} size="2x" />
              <p><strong>{videoCozumListe.length}</strong></p>
              <p>Toplam Video Çözüm</p>
            </div>
          </Link>

          <Link className="cardAlanLink" to="/panel/admin/videocozum">
            <div className="card cardAlan-cardd bg-success">
              <FontAwesomeIcon icon={faPlay} size="2x" />
              <p><strong>{profileCompanyInfo.paths ? profileCompanyInfo.paths.length : "0"}</strong></p>
              <p>Aktif Video Çözüm</p>
            </div>
          </Link>

        </div>

      </div>
    </>
  )
};

export default Home;