import React, { useEffect } from "react";

// !!! DATATABLE !
import Table from "./Table/UserTable/UserDataTable";
import "./Table/styles.css";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// REDUX
import { useDispatch } from 'react-redux';
import { fetchTumKurumListe,fetchTumUserListe } from '../../store/actions/actions';


const AdminUser = () => {

    const updateClickHandler = (updateAlan) => {
      // console.log("Güncelle", updateAlan);
    }
    const deleteClickHandler = deleteAlan => {
      // console.log("Sil", deleteAlan);
    }

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchTumKurumListe());
      dispatch(fetchTumUserListe());
    }, [dispatch]);

  return (
    <>
    <Table updateClickHandler={updateClickHandler} deleteClickHandler={deleteClickHandler} />
    <ToastContainer/>
    </>
  );
};

export default AdminUser;