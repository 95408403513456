import axios from "axios";

const HTTP = axios.create({
  // baseURL: "http://localhost:5000/",
  // baseURL: "http://217.116.201.40:36771/",
    baseURL:"https://admin.acisurecizleme.com.tr/",
});

export const cookieVerify = async () =>
  await HTTP.post("/panel", {} , { withCredentials: true });

export const signup = async (inputValue) =>
  await HTTP.post("/panel/signup", {...inputValue,} , { withCredentials: true });

export const login = async (formData) =>
  await HTTP.post("/panel/login", formData , { withCredentials: true });

export const logout = async () =>
  await HTTP.post("/panel/admin/logout", { } ,{ withCredentials: true });

// Admin User axios

  export const userlisteler = async () =>
  await HTTP.get("/panel/admin/userliste");

  export const userekle = async (formData) =>
  await HTTP.post("/panel/admin/userekle", formData , { withCredentials: true });

  export const userupdate = async (id,updatedFormData) =>
  await HTTP.put(`/panel/admin/userupdate/${id}`,updatedFormData);

  export const usermultipleisactiveupdate = async (selectedMultipleUpdatedItems) =>
  await HTTP.put(`/panel/admin/usermultipleisactiveupdate`,selectedMultipleUpdatedItems);

  export const userdelete = async (id) =>
  await HTTP.delete(`/panel/admin/userdelete/${id}`);

// Admin Company axios

  export const kurumlisteler = async () =>
  await HTTP.get("/panel/admin/kurumliste");

  export const kurumekle = async (formData) =>
  await HTTP.post("/panel/admin/kurumekle", formData , { withCredentials: true });

  export const kurumupdate = async (id,updatedFormData) =>
   await HTTP.put(`/panel/admin/kurumupdate/${id}`,updatedFormData);

   export const kurummultipleisactiveupdate = async (selectedMultipleUpdatedItems) =>
   await HTTP.put(`/panel/admin/kurummultipleisactiveupdate`,selectedMultipleUpdatedItems);

   export const kurumdelete = async (id) =>
   await HTTP.delete(`/panel/admin/kurumdelete/${id}`);

   // Admin VideoSolution axios

   export const videocozumekle = async (preparedLastData) =>
   await HTTP.post("/panel/admin/videocozumekle", preparedLastData , { withCredentials: true });

   export const videocozumliste = async () =>
   await HTTP.get("/panel/admin/videocozumliste");

  // Admin Profile axios

   export const profilkurumbilgigetir = async (id) =>
   await HTTP.get(`/panel/admin/profilkurumbilgigetir/${id}`);


//    // Türkiyedeki iller ve ilçelerini api olarak alıyoruz.
//    export const fetchUsers = async () =>
//     await HTTP.get("https://turkiyeapi.cyclic.app/api/v1/provinces");

//     export const feedbackAdd = async (formData) =>
//     await HTTP.post("/feedbacks/aciadmin/messages", formData);

//     export const feedbackListe = async () =>
//     await HTTP.get("/feedbacks/aciadmin/messages");

