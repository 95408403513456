import React, { useState, useRef } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { signup } from "../../../../axios/axios";

// SELECT OPTİON'DA KULLANILAN İLLER
import siniflar from "../../../../service/siniflar.json";
// MODAL CSS
import "../../../css/Kurum/KurumModal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const KurumStudentAdd = ({userData}) => {

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    TCNO: "",
    kurumAd: userData.kurumAd,
    kurumID: userData.kurumID,
    password: "",
    studentClass:"",
    userType: "OGRENCI",
    isActive: false,
  });

  const formDataSıfırla = () => {
    setFormData({
      fullname: "",
      email: "",
      phoneNumber: "",
      TCNO: "",
      kurumAd: userData.kurumAd,
      kurumID: userData.kurumID,
      password: "",
      studentClass:"",
      userType: "OGRENCI",
      isActive: false,
    });
  }

  const formemail = useRef();
  const formfullname = useRef();
  const formpassword = useRef();
  const formphonenumber = useRef();
  const formTCNO = useRef();
  const formcompany = useRef();
  const formclass = useRef();



  // VALIDATION AREA
  const handleError = (err) =>
    toast.error(err, {
      position: "top-right",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const generalSettings = (() => {
    // GENEL VALIDATION
    !formfullname.current.value ? formfullname.current.style.borderColor = "red" : formfullname.current.style.borderColor = "#ced4da";
    !formemail.current.value ? formemail.current.style.borderColor = "red" : formemail.current.style.borderColor = "#ced4da";
    !formphonenumber.current.value ? formphonenumber.current.style.borderColor = "red" : formphonenumber.current.style.borderColor = "#ced4da";
    !formTCNO.current.value ? formTCNO.current.style.borderColor = "red" : formTCNO.current.style.borderColor = "#ced4da";
    !formpassword.current.value ? formpassword.current.style.borderColor = "red" : formpassword.current.style.borderColor = "#ced4da";
    !formcompany.current.value ? formcompany.current.style.borderColor = "red" : formcompany.current.style.borderColor = "#ced4da";
    !formclass.current.value ? formclass.current.style.borderColor = "red" : formclass.current.style.borderColor = "#ced4da";

    // PHONE NUMBER VALIDATION
    if (formphonenumber.current.value.length !== 11) {
      toast.error("Telefon Numarası 11 haneli olmalıdır !")
      formphonenumber.current.style.borderColor = "red"
    }
    else {
      formphonenumber.current.style.borderColor = "#ced4da"
    }

    // TCNO VALIDATION
    if (formTCNO.current.value.length !== 11) {
      toast.error("TC Kimlik No 11 haneli olmalıdır !")
      formTCNO.current.style.borderColor = "red"
    }
    else {
      formTCNO.current.style.borderColor = "#ced4da"
    }

    // PASSWORD VALIDATION
    if (formpassword.current.value.length < 6) {
      toast.error("Parola en az 6 karakterden oluşmalıdır !")
      formpassword.current.style.borderColor = "red"
    }
    else {
      formpassword.current.style.borderColor = "#ced4da"
    }

  })

  const errorCatch = (message) => {

    // EMAIL VALIDATION
    if (message === "Bu e-posta adresiyle bir hesap zaten mevcut. Lütfen başka bir e-posta adresi deneyin") {
      formemail.current.style.borderColor = "red";
      formpassword.current.value = "";
      formpassword.current.style.borderColor = "red";
    }

    // TC KİMLİK NO VALIDATION
    if (message === "Bu TC kimlik numarası ile bir hesap zaten mevcut") {
      formTCNO.current.style.borderColor = "red"
      formpassword.current.value = "";
      formpassword.current.style.borderColor = "red";
    }
  }

  return (
    <>
      <Button className="CompanyStudentAddButton companyListAddButton" variant="primary" onClick={handleShow}>
        <FontAwesomeIcon className="me-3" icon={faPlus} size="1x" />
        Yeni Oluştur
      </Button>

      <Modal dialogClassName="modal-dialog kurumUserAddModalDialog" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcı Ekleme</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
                <Form
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    // console.log(formData)
                    generalSettings();

                    signup(formData)
                      .then((res) => {

                        const { message, success } = res.data;
                        if (success) {
                          handleClose()
                          handleSuccess(message)
                          formDataSıfırla()
                        }
                        else {
                          // console.log(message)
                          handleError(message)
                          errorCatch(message)
                        }

                      })
                      .catch((err) => toast.error(err.response.data.message));
                  }}
                >


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Ad Soyad</Form.Label>
                    <Form.Control
                      ref={formfullname}
                      onChange={(e) =>
                        setFormData({ ...formData, fullname: e.target.value.toLocaleUpperCase('tr-TR') })
                      }
                      type="name"
                      placeholder="Ad Soyad Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-Posta</Form.Label>
                    <Form.Control
                      ref={formemail}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      type="email"
                      placeholder="E-Posta adresinizi giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>Telefon No</Form.Label>
                    <Form.Control
                      ref={formphonenumber}
                      onChange={(e) =>
                        setFormData({ ...formData, phoneNumber: e.target.value })
                      }
                      type="text"
                      placeholder="Telefon numaranızı giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicTC">
                    <Form.Label>TC Kimlik No</Form.Label>
                    <Form.Control
                      ref={formTCNO}
                      onChange={(e) =>
                        setFormData({ ...formData, TCNO: e.target.value })
                      }
                      type="text"
                      placeholder="TC Kimlik No giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label>Kurum Adı</Form.Label>
                    <Form.Control
                      ref={formcompany}
                      value={userData.kurumAd}
                      readOnly
                      type="text"
                      placeholder="Kurum Adı giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicSınıf">
                  <Form.Label>Sınıf</Form.Label>
                  <Form.Select ref={formclass} defaultValue="" className="ps-5" onChange={(e) => {
                    setFormData({ ...formData, studentClass: e.target.value })
                  }
                  }>
                    <option value="" disabled>Sınıf Seç</option>
                    {siniflar.map((sinif) => {
                      return (
                        <option key={sinif.id}>{sinif.sinif}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Parola</Form.Label>
                    <Form.Control
                      ref={formpassword}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      type="password"
                      placeholder="Parolanızı oluşturunuz"
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicUserType">
                    <Form.Label>Kullanıcı Türü</Form.Label>
                    <Form.Control
                      readOnly
                      value="Öğrenci"
                      type="text"
                      placeholder="Kullanıcı Türü seçiniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formUserActive">
                    <Form.Label>Kullanıcı Durumu</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) => {
                      setFormData({ ...formData, isActive: e.target.value });
                      // console.log(e.target.value)
                    }
                    }>
                      <option value="" disabled>Kullanıcı Durumu Seçiniz</option>
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>


                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="d-grid">


                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Kayıt Ekle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );

};
export default KurumStudentAdd;
