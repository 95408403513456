import React, { useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../css/theme";
import { useTheme, Box, IconButton } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useProSidebar } from "react-pro-sidebar";
import "../css/theme"
import "../css/Topbar.css"
// import {userLogout} from "../../../adminekran/axios/index";

import { setCookie, getCookie, getCookies, deleteCookie, hasCookie } from 'cookies-next';


//Yönlendirme
import { useNavigate  } from "react-router-dom";
import { useCookies } from "react-cookie";

import { logout } from "../../axios/axios";

const Topbar = ({userData}) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [profilBilgiAc,setProfilBilgiAc] = useState("");
  // const [cookies, setCookie, removeCookie] = useCookies(['token']);

  //Yönlendirme
  const navigate  = useNavigate();
  // Açılan user bilgi ekranı

  const openUserFunc = () => {
    if(profilBilgiAc === true){
      setProfilBilgiAc(false)
    }
    else{
      setProfilBilgiAc(true)
    }
  }

  // const Logout = () => {
  //   deleteCookie("token", { path: '/', domain:".egitimicerikvideocozum-frontend.onrender.com"});
  //   navigate("/panel/login");
  // };

  const Logout = () => {
    logout()
      .then(response => {
        // console.log(response.data); // "Logout successful"
        navigate("/panel/uyegiris");
      })
      .catch(error => {
        console.error(error);
      });
  };



// console.log(userData)


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}

      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (

            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>

        <div className="openBoxDiv">
          <IconButton  onClick={openUserFunc} >
            <PersonOutlinedIcon />
          </IconButton>

          {profilBilgiAc === true && userData !== null ?
            <Box className="openUserBox">
                 <h6>KULLANICI TÜRÜ</h6>
                 <p>{userData.userType === "OGRENCI" ? "ÖĞRENCİ" : userData.userType }</p>

                 <h6>AD-SOYAD</h6>
                 <p>{userData.fullname}</p>
                 <h6>E-POSTA</h6>
                 <p>{userData.email}</p>
                 <h6>KURUM ADI</h6>
                 <p>{userData.kurumAd}</p>
                 <button className="mt-2 btn btn-danger cikisyapButton" onClick={Logout}>Çıkış Yap</button>

            </Box>
            :
              null
          }
        </div>


        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}

      </Box>
    </Box>
  );
};

export default Topbar;
