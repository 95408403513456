import React, { useRef } from "react";

// ILLER DATA
import iller from "../../../../service/iller.json"

// !!! ICONLAR !!!
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight, faSearch, faX } from '@fortawesome/free-solid-svg-icons';

// COMPONENTS
import AdminCompanyAdd from "../CompanyTable/AdminCompanyAdd";
// CSS
import styled from "styled-components";
import "../../../css/Admin/DataTableFilterComponent.css";

// AXIOS
import { kurummultipleisactiveupdate } from "../../../../axios/axios";

// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const FilterComponent = ({ filterText, onFilter, onClear,selectedMultipleUpdatedItems, setSecilenSehir, setSecilenKurumTur, setKayitBasarili, secilenSehir }) => {

  const kurumTurRef = useRef();

  const multipleUpdate = (()=>{
    console.log(selectedMultipleUpdatedItems)

    kurummultipleisactiveupdate(selectedMultipleUpdatedItems)
    .then((res) => {
      console.log(res.data.result.modifiedCount)
      const userUpdateNumber = res.data.result.modifiedCount; // Kaç kullanıcı güncellendi
      const { success } = res.data;
      if (success) {
        toast.success(`${userUpdateNumber} Kurum başarıyla güncellendi !`);
      }
      else {
        toast.danger("Kurum güncellenemedi !")

      }

    })
    .catch((err) => console.log(err));

  })

  return(

  <div style={{ width: "100%" }}>


    <div className="search-and-add-area">
      <div className="searchFilterAlan">
        <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
        <Input
          id="search"
          type="text"
          placeholder="Tabloda Ara..."
          className="headerFilter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton className="headerClear" onClick={onClear}><FontAwesomeIcon icon={faX} /></ClearButton>

      </div>

      <div>
          <button className="btn btn-primary multipleIsActiveUpdateButton companyListUpdateButton" onClick={multipleUpdate}>
          <FontAwesomeIcon className="me-3" icon={faArrowRotateRight}></FontAwesomeIcon>
            <p style={{display:"inline", fontFamily:"sans-serif", fontSize:"18px"}}>Durum Güncelle</p>
            </button>
        </div>

      <div>
        <AdminCompanyAdd setKayitBasarili={setKayitBasarili} ></AdminCompanyAdd>
      </div>
    </div>


    <div className="headerOptionAlan mt-3">
      <select
        defaultValue="sehirsec"
        onChange={(e) =>
          e.target.value === "sehirsec" ? setSecilenSehir("")+ (kurumTurRef.current.value = "Default") : setSecilenSehir(e.target.value)+setSecilenKurumTur("") + (kurumTurRef.current.value = "Default")
        }
        className="headerSelect "
      >+-
        <option value="sehirsec">Şehir Seç</option>

        {iller.map((e) => {
          return (
            <option key={e.plaka}>
              {e.il_adi}
            </option>
          );
        })}
      </select>

      <select
        ref={kurumTurRef}
        defaultValue={"Default"}
        onChange={(e) =>
          e.target.value === "Default" ? setSecilenKurumTur("") : setSecilenKurumTur(e.target.value)
        }
        className="headerSelect"
      >
        <option value="Default">Kurs/Kolej Seç</option>
        <option value="Kolej">Kolej</option>
        <option value="Kurs">Kurs</option>
      </select>

    </div>

  </div>

)};

export default FilterComponent;
