import React, { useMemo, useState} from "react";

// !!! DATATABLE İMPORTLAR !!!
import DataTable from "react-data-table-component";
import FilterComponent from "./UserFilterComponent";

// MODAL CSS
import "../../../css/Admin/Modal.css"

// PAGES
import AdminUserUpdate from "../UserTable/AdminUserUpdate";
import AdminUserDelete from "./AdminUserDelete";

// REDUX
import { useSelector } from "react-redux";

// !!! İCONLAR !!!
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash,faCheck,faXmark } from '@fortawesome/free-solid-svg-icons';



const UserDataTable = props => {


console.log(props)
  const [updateBilgiler, setUpdateBilgiler] = useState();


  const tumUserListe = useSelector(state => state.tumUserListe);
  console.log(tumUserListe)

  const updateClickHandler = (updateAlan) => {
    console.log("Güncelle", updateAlan);
    setUpdateBilgiler(updateAlan)
  }

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);

  const [selectedMultipleUpdatedItems, setSelectedMultipleUpdatedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Mevcut sayfa takibi

  // function turkishToUpper(string) {
  //   const letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
  //   string = string.replace(/(([iışğüçö]))/g, letter => letters[letter]);
  //   return string.toUpperCase();
  // }

  function turkishToLower(string) {
    const letters = { "İ": "i", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç", "I": "ı" };
    string = string.replace(/(([İŞĞÜÇÖ]))/g, letter => letters[letter]);
    return string.toLowerCase();
  }

  const UserStatusCell = ({ row, selectedMultipleUpdatedItems, setSelectedMultipleUpdatedItems }) => {
  const user = row;
  const [isActive, setIsActive] = useState(user.isActive || false);


  const handleCheckboxClick = () => {
    setIsActive((prevIsActive) => !prevIsActive);

    const rowId = user._id;

    const exists = selectedMultipleUpdatedItems.some(item => item.rowId === rowId);

    // selectedMultipleUpdatedItems.push({rowId,isActive})
    console.log(selectedMultipleUpdatedItems)
    console.log(isActive)

    if (exists) {
      const index = selectedMultipleUpdatedItems.findIndex(item => item.rowId === rowId);
      selectedMultipleUpdatedItems.splice(index, 1);
    } else {
      selectedMultipleUpdatedItems.push({ rowId, isActive: !isActive });
    }
  };
  return (
    <div
      className={`checkbox-div ${isActive ? 'checked' : ''}`}
      onClick={handleCheckboxClick}
    >
      <div className="checkbox-icon">{isActive ?
       <FontAwesomeIcon style={{color:"green"}} size="2x" icon={faCheck}></FontAwesomeIcon>
       :
       <FontAwesomeIcon style={{color:"red"}} size="2x" icon={faXmark}></FontAwesomeIcon>
       }</div>

    </div>


  );
};

  const columns = [
    {
      name: "Ad Soyad",
      selector: (row) => row.fullname,
      sortable: true,
      grow: 1,
    },
    {
      name: "E-posta",
      selector: row => row.email,
      sortable: true,
      hide: "sm",
      grow: 1
    },
    {
      name: "Telefon No",
      selector: row => row.phoneNumber,
      sortable: true,
      hide: "lg",
      grow: 1
    },
    {
      name: "Kullanıcı Türü",
      selector: row => row.userType,
      sortable: true,
      hide: "lg",
      grow: 1
    },
    {
      name: "Durum",
      button: true,
      cell: (row) => <UserStatusCell row={row} selectedMultipleUpdatedItems={selectedMultipleUpdatedItems} setSelectedMultipleUpdatedItems={setSelectedMultipleUpdatedItems} />,
    },
    {
      name: "İşlemler",
      button: true,
      cell: row =>
      (
        <>
          <div onClick={() => {
            updateClickHandler(row)
            console.log(row)
            setShow(true)
          }}>
            <AdminUserUpdate setShow={setShow} show={show} updateBilgiler={updateBilgiler}></AdminUserUpdate>
          </div>

          <AdminUserDelete  deleteBilgiler={row} icon={faTrash}></AdminUserDelete>

        </>
      )
    }
  ];

  const [secilenKullaniciTur, setSecilenKullaniciTur] = React.useState("");
  const [secilenOgrenciSinif, setSecilenOgrenciSinif] = React.useState("");
  const [secilenKullaniciDurum, setSecilenKullaniciDurum] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


  const filterMethods = [
    (item => item.userType.toLowerCase().includes(secilenKullaniciTur.toLowerCase())),
    (item => item.studentClass.toLowerCase().includes(secilenOgrenciSinif.toLowerCase())),
    (item => (item.isActive ? 'true' : 'false').includes(secilenKullaniciDurum.toLowerCase())),
    (item => turkishToLower(JSON.stringify(item)).includes(turkishToLower(filterText)))
  ]

  const filteredArray = tumUserListe.filter((item) => {
    const lowercaseFullname = turkishToLower(item.fullname);
    const lowercaseEmail = turkishToLower(item.email);
    const lowercasePhoneNumber = turkishToLower(item.phoneNumber);
    const lowercaseUserType = turkishToLower(item.userType);

    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i]({
        ...item,
        fullname: lowercaseFullname,
        email: lowercaseEmail,
        phoneNumber: lowercasePhoneNumber,
        userType: lowercaseUserType
      })) {
        console.log("filter çalışmadı");
        return false;
      }
    }

    return true;
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));  // Tarihe göre sıralama
  ;

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };


    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value.toUpperCase('tr-TR'))}
        onClear={handleClear}
        filterText={filterText}
        setSecilenKullaniciTur={setSecilenKullaniciTur}
        setSecilenOgrenciSinif={setSecilenOgrenciSinif}
        setSecilenKullaniciDurum={setSecilenKullaniciDurum}
        selectedMultipleUpdatedItems={selectedMultipleUpdatedItems}
        setSelectedMultipleUpdatedItems={setSelectedMultipleUpdatedItems}
      />
    );
  }, [filterText, resetPaginationToggle, secilenKullaniciTur,secilenOgrenciSinif, secilenKullaniciDurum,selectedMultipleUpdatedItems]);

  const handlePageChange = (page) => {
    setCurrentPage(page); // Mevcut sayfayı ayarla
    console.log(`Şu anda ${page}. sayfadasın`);
  };


  return (
    <DataTable
      title="Kullanıcı Listesi"
      columns={columns}
      data={filteredArray}
      defaultSortField="name"
      striped
      pagination
      onChangePage={handlePageChange}
      subHeader
      subHeaderComponent={subHeaderComponent}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationPerPage={50}
      noDataComponent="Herhangi bir veriye ulaşılmadı"
      paginationComponentOptions={{
        rowsPerPageText: "Sayfa başına kayıt sayısı:"
      }}
    />
  );
};

export default UserDataTable;
