import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router-dom";

// COOKİE ÇEREZ
import { useCookies } from "react-cookie";

// AXİOS
import {cookieVerify} from "./axios/axios.js";

// Sidebar ve Topbar Ayarları
import { ColorModeContext, useMode } from "./layout/css/theme.js";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyProSidebarProvider } from "./layout/components/sidebarContext.js";
import Topbar from "./layout/components/Topbar.jsx";

// Login Signup Pages
import LoginPage from "./pages/Login.jsx";
import SignupPage from "./pages/Signup.jsx";

// Admin Pages
import AdminHome from "./pages/admin/AdminHome";
import AdminCompany from "./pages/admin/AdminCompany.jsx";
import AdminUser from "./pages/admin/AdminUser.jsx";
import AdminVideoSolution from "./pages/admin/AdminVideoSolution.jsx";
import AdminCompanyProfile from "./pages/admin/AdminCompanyProfile.jsx";
import AdminUserProfile from "./pages/admin/AdminUserProfile.jsx";

// Kurum Pages
import KurumHome from "./pages/kurum/KurumHome";
import KurumStudentManage from "./pages/kurum/KurumStudentManage.jsx";
import KurumCompanyProfile from "./pages/kurum/KurumCompanyProfile.jsx";
import KurumUserProfile from "./pages/kurum/KurumUserProfile.jsx";
import KurumVideoSolution from "./pages/kurum/KurumVideoSolution.jsx";

// Ogrenci Pages
import OgrenciUserProfile from "./pages/ogrenci/OgrenciUserProfile";
import OgrenciVideoSolution from "./pages/ogrenci/OgrenciVideoSolution";




function App() {

  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, removeCookie] = useCookies([]);
  const [userData, setUserData] = useState("");
  const [theme, colorMode] = useMode();

  useEffect(() => {
    const verifyCookie = async () => {

      cookieVerify()
      .then((res)=>{
        const { user } = res.data;
        // console.log(user)
        setUserData(user);
        if (!user && location.pathname !== "/panel/uyekayit") {
          navigate("/panel/uyegiris");
        }

        if(user && user.userType === "ADMIN" && !location.pathname.includes("/panel/admin")){
          navigate("/panel/admin")
        }
        else if(user && user.userType === "KURUM" && !location.pathname.includes("/panel/kurum")){
          navigate("/panel/kurum")
        }
        else if(user && user.userType === "OGRENCI" && !location.pathname.includes("/panel/ogrenci")){
          navigate("/panel/ogrenci")
        }
      })
      .catch((err)=>{
        console.log(err)
      })


    };
    verifyCookie();
  }, [cookies, navigate, removeCookie,location.pathname]);


  return (

    <div className="App">

      {location.pathname === "/panel/uyegiris" || location.pathname === "/panel/uyekayit" || !userData ?  (
        <Routes>
          <Route path="/*" element={<LoginPage userData={userData} />} />
          <Route path="/panel/uyegiris" element={<LoginPage userData={userData} />} />
          <Route path="/panel/uyekayit" element={<SignupPage />} />
        </Routes>
      ) :

        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MyProSidebarProvider userData={userData}>
              <div className="container">
                <div style={{ height: "100%", width: "100%" }}>

                  <Topbar userData={userData} />

                  <Routes>

                    <Route path="/panel/admin/*" element={userData && userData.userType === "ADMIN" && <AdminHome userData={userData} />} />
                    <Route path="/panel/admin/kurum" element={userData && userData.userType === "ADMIN" && <AdminCompany userData={userData} />} />
                    <Route path="/panel/admin/kullanici" element={userData && userData.userType === "ADMIN" && <AdminUser userData={userData} />} />
                    <Route path="/panel/admin/videocozum" element={userData && userData.userType === "ADMIN" && <AdminVideoSolution userData={userData} />} />
                    <Route path="/panel/admin/kurumprofil" element={userData && userData.userType === "ADMIN" && <AdminCompanyProfile userData={userData} />} />
                    <Route path="/panel/admin/kullaniciprofil" element={userData && userData.userType === "ADMIN" && <AdminUserProfile userData={userData} />} />

                    <Route path="/panel/kurum/*" element={userData && userData.userType === "KURUM" && <KurumHome userData={userData} />} />
                    <Route path="/panel/kurum/kurumprofil" element={userData && userData.userType === "KURUM" && <KurumCompanyProfile userData={userData} />} />
                    <Route path="/panel/kurum/kullaniciprofil" element={userData && userData.userType === "KURUM" && <KurumUserProfile userData={userData} />} />
                    <Route path="/panel/kurum/ogrenciyonetim" element={userData && userData.userType === "KURUM" && <KurumStudentManage userData={userData} />} />
                    <Route path="/panel/kurum/videocozum" element={userData && userData.userType === "KURUM" && <KurumVideoSolution userData={userData} />} />


                    <Route path="/panel/ogrenci/*" element={userData && userData.userType === "OGRENCI" && <OgrenciVideoSolution userData={userData} />} />
                    <Route path="/panel/ogrenci/kullaniciprofil" element={userData && userData.userType === "OGRENCI" && <OgrenciUserProfile userData={userData} />} />
                    <Route path="/panel/ogrenci/videocozum" element={userData && userData.userType === "OGRENCI" && <OgrenciVideoSolution userData={userData} />} />

                  </Routes>


                </div>
              </div>
            </MyProSidebarProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      }

    </div>
  );
}

export default App;