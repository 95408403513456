// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../css/theme";

// İCONLAR
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BusinessIcon from '@mui/icons-material/Business';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import GroupIcon from '@mui/icons-material/Group';
import AciLogo from "../acilogo.png";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = ({ userData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  const renderMenuItems = () => {
    if (!userData || !userData.userType) {
      // Kullanıcı verisi yoksa veya userType bilgisi yoksa bir hata durumu ya da varsayılan durumu ele alabilirsiniz.
      return (
        <MenuItem>
          <Typography color={colors.grey[100]}>Hata: Kullanıcı bilgileri eksik</Typography>
        </MenuItem>
      );
    }

    // Kullanıcı türüne göre farklı menü öğelerini döndür
    switch (userData.userType) {
      case "ADMIN":
        return (
          <>
            {/* ADMIN Menü Öğeleri */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Admin İçerik
            </Typography>

            <Item
              title="Gösterge Paneli"
              to="/panel/admin"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Yönetim
            </Typography>

            <Item
              title="Kurum Yönetimi"
              to="/panel/admin/kurum"
              icon={<BusinessIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Kullanıcı Yönetimi"
              to="/panel/admin/kullanici"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Video Çözüm Yönetimi"
              to="/panel/admin/videocozum"
              icon={<OndemandVideoIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Kurum Profil"
              to="/panel/admin/kurumprofil"
              icon={<GroupIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Kullanıcı Profil"
              to="/panel/admin/kullaniciprofil"
              icon={<AccountBoxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Geri Bildirimler"
              to="/panel/admin/messages"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


          </>
        );
      case "KURUM":
        return (
          <>
            {/* KURUM Menü Öğeleri */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Kurum İçerik
            </Typography>

            <Item
              title="Ana Sayfa"
              to="/panel/kurum"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Kurum Profil"
              to="/panel/kurum/kurumprofil"
              icon={<BusinessIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Kullanıcı Profil"
              to="/panel/kurum/kullaniciprofil"
              icon={<AccountBoxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Yönetim
            </Typography>


            <Item
              title="Öğrenci Yönetimi"
              to="/panel/kurum/ogrenciyonetim"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Video Çözümler"
              to="/panel/kurum/videocozum"
              icon={<OndemandVideoIcon />}
              selected={selected}
              setSelected={setSelected}
            />


          </>

        );
      case "OGRENCI":
        return (
          <>
            {/* OGRENCI Menü Öğeleri */}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              Öğrenci İçerik
            </Typography>

            <Item
              title="Kullanıcı Profil"
              to="/panel/ogrenci/kullaniciprofil"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Video Çözümler"
              to="/panel/ogrenci/videocozum"
              icon={<OndemandVideoIcon />}
              selected={selected}
              setSelected={setSelected}
            />

          </>
        );
      default:
        // Bilinmeyen veya desteklenmeyen bir kullanıcı türü durumunda bir hata durumu
        return (
          <MenuItem>
            <Typography color={colors.grey[100]}>Hata: Bilinmeyen kullanıcı türü</Typography>
          </MenuItem>
        );
    }
  };


  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="lg"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
        image={sidebarImage}
      >

        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : ""

            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  AÇI ÇÖZÜM
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "& .avater-image": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <img
                  className="avater-image"
                  alt="Açı logo"
                  width="100px"
                  height="100px"
                  src={AciLogo}                      // Fotoğraf için bu alan kullanılır.
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {localStorage.getItem("fullname")}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={collapsed ? undefined : "10%"}>
            {renderMenuItems()}
          </Box>
        </Menu>

      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
