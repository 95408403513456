// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    /* text-align: center; */
  }
  
  .rdt_Table {
    margin-bottom: 1rem;
  }
  .rdt_TableRow {
    background-color: grey;
  }
  .rdt_TableCol {
    color: black;
    font-weight: bolder;
  }
  .rdt_TableCol_Sortable {
  }
  .rdt_TableCell {
  }
  .rdt_TableHeader {
    background-color: transparent;
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    background-color: green;
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }
  
  .checkbox-div {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* background-color: #ff2626; */
  }
  
  .checkbox-icon {
    width: 20px;
    height: 20px;
    /* border: 1px solid #000; */
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  /* .checked {
    background-color: #3aff89;
  } */
  
  .checkbox-label {
    flex: 1 1;
  }`, "",{"version":3,"sources":["webpack://./src/pages/admin/Table/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;EACA;EACA;EACA;EACA;IACE,6BAA6B;EAC/B;EACA;EACA;EACA;EACA;EACA;IACE,uBAAuB;EACzB;EACA;EACA;EACA;EACA;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;EAEzB;;EAEA;;KAEG;;EAEH;IACE,SAAO;EACT","sourcesContent":[".App {\n    font-family: sans-serif;\n    /* text-align: center; */\n  }\n  \n  .rdt_Table {\n    margin-bottom: 1rem;\n  }\n  .rdt_TableRow {\n    background-color: grey;\n  }\n  .rdt_TableCol {\n    color: black;\n    font-weight: bolder;\n  }\n  .rdt_TableCol_Sortable {\n  }\n  .rdt_TableCell {\n  }\n  .rdt_TableHeader {\n    background-color: transparent;\n  }\n  .rdt_TableFooter {\n  }\n  .rdt_TableHead {\n  }\n  .rdt_TableHeadRow {\n    background-color: green;\n  }\n  .rdt_TableBody {\n  }\n  .rdt_ExpanderRow {\n  }\n  \n  .checkbox-div {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    padding: 6px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    /* background-color: #ff2626; */\n  }\n  \n  .checkbox-icon {\n    width: 20px;\n    height: 20px;\n    /* border: 1px solid #000; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n   \n  }\n  \n  /* .checked {\n    background-color: #3aff89;\n  } */\n  \n  .checkbox-label {\n    flex: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
