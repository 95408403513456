// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginScreen{
    height: 100vh;
    background: #4568DC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #B06AB3, #4568DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.form-signin{
    padding: 30px;
    background-color: aliceblue;
    width: 50%;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.form-control{
    margin-top: 10px;
    padding-left: 40px;
}
.form-email{
    position: relative;
}
.form-pass{
    position: relative;
}
.passkey-icon{
    position: absolute;
    left: 10px;
    top: 9px;
    font-size: 20px;
}
.email-icon{
    position: absolute;
    left: 10px;
    top: 9px;
    font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .form-signin{
    width: 80%;
  }
   }
   @media only screen and (max-height: 490px) {
    .loginScreen{
      height: 100%;
    }
     }`, "",{"version":3,"sources":["webpack://./src/pages/css/LoginAndSignup/LoginScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,GAAG,8BAA8B,GACc,+BAA+B;IACjG,uDAAuD,EAAE,qEAAqE;IAC9H,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;;AAEtB;AACA;IACI,aAAa;IACb,2BAA2B;IAC3B,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,eAAe;AACnB;;AAEA;EACE;IACE,UAAU;EACZ;GACC;GACA;IACC;MACE,YAAY;IACd;KACC","sourcesContent":[".loginScreen{\n    height: 100vh;\n    background: #4568DC;  /* fallback for old browsers */\n    background: -webkit-linear-gradient(to right, #B06AB3, #4568DC);  /* Chrome 10-25, Safari 5.1-6 */\n    background: linear-gradient(to right, #B06AB3, #4568DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n\n}\n.form-signin{\n    padding: 30px;\n    background-color: aliceblue;\n    width: 50%;\n    border-radius: 10px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n.form-control{\n    margin-top: 10px;\n    padding-left: 40px;\n}\n.form-email{\n    position: relative;\n}\n.form-pass{\n    position: relative;\n}\n.passkey-icon{\n    position: absolute;\n    left: 10px;\n    top: 9px;\n    font-size: 20px;\n}\n.email-icon{\n    position: absolute;\n    left: 10px;\n    top: 9px;\n    font-size: 20px;\n}\n\n@media only screen and (max-width: 991px) {\n  .form-signin{\n    width: 80%;\n  }\n   }\n   @media only screen and (max-height: 490px) {\n    .loginScreen{\n      height: 100%;\n    }\n     }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
