// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ogrenciProfileCol {
    background-color: white;
    padding: 40px;
    border-radius: 2px;
    color: black;
}

.adminProfileSelect {
    background-color: #c9c9c9;
}

.ogrenciProfileInput {
    background-color: #c9c9c9;
}

`, "",{"version":3,"sources":["webpack://./src/pages/css/Ogrenci/OgrenciUserProfile.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".ogrenciProfileCol {\r\n    background-color: white;\r\n    padding: 40px;\r\n    border-radius: 2px;\r\n    color: black;\r\n}\r\n\r\n.adminProfileSelect {\r\n    background-color: #c9c9c9;\r\n}\r\n\r\n.ogrenciProfileInput {\r\n    background-color: #c9c9c9;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
